/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * E.ON Home Dashboard - SSE (Server Sent Event) API
 *  This API allows you to subscribe to the event stream of E.ON Home.  **Example of a client implementation:**  ---  Install the Event Source Polyfill client from https://www.npmjs.com/package/eventsource  ``` npm install eventsource ```  Put this in your index.html:  ``` var eventSourceInitDict = {    headers: {     \"Authorization\": \"Bearer XXXXXXXXXXXXX\"   } };   var url = \"https://sse.api.dev.eonhome.eu/api/v1/stream\";  var source = new EventSourcePolyfill(url, eventSourceInitDict    source.onopen = function(e) {     console.log(\"Stream open\");  };    source.addEventListener(\"gcp-telemetries\", function(e) {     document.body.innerHTML += e.data + \" from event: gcp-telemetries<br>\";  }, false); ``` ---  **Available Events:**    * gcp-telemetries   * wallbox-telemetries   * pvbattery-telemetries   * dhw-telemetries   * compressor-telemetries   * hvac-telemetries   * smarthome-telemetries   * notifications   * more tbc. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: hassan.harb@eon.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://sse-hems-dev.eon.ooo".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface BatteryDataModel
 */
export interface BatteryDataModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof BatteryDataModel
     */
    power?: TotalDataModel;
    /**
     * 
     * @type {EnergyBatteryDataModel}
     * @memberof BatteryDataModel
     */
    energy?: EnergyBatteryDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof BatteryDataModel
     */
    current?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface CompressorDataResponseModel
 */
export interface CompressorDataResponseModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof CompressorDataResponseModel
     */
    power?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface CompressorResponseModel
 */
export interface CompressorResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof CompressorResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof CompressorResponseModel
     */
    creationTime?: number;
    /**
     * 
     * @type {string}
     * @memberof CompressorResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompressorResponseModel
     */
    deviceType?: string;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof CompressorResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {CompressorDataResponseModel}
     * @memberof CompressorResponseModel
     */
    data?: CompressorDataResponseModel;
}

/**
 * 
 * @export
 * @interface DhwDataResponseModel
 */
export interface DhwDataResponseModel {
    /**
     * 
     * @type {DhwDataTemperatureResponseModel}
     * @memberof DhwDataResponseModel
     */
    temperature?: DhwDataTemperatureResponseModel;
}

/**
 * 
 * @export
 * @interface DhwDataTemperatureResponseModel
 */
export interface DhwDataTemperatureResponseModel {
    /**
     * 
     * @type {number}
     * @memberof DhwDataTemperatureResponseModel
     */
    flow?: number;
}

/**
 * 
 * @export
 * @interface EnergyBatteryDataModel
 */
export interface EnergyBatteryDataModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof EnergyBatteryDataModel
     */
    battery2home?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof EnergyBatteryDataModel
     */
    home2battery?: TotalDataModel;
    /**
     * 
     * @type {number}
     * @memberof EnergyBatteryDataModel
     */
    stateOfCharge?: number;
}

/**
 * 
 * @export
 * @interface EnergyPvDataModel
 */
export interface EnergyPvDataModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof EnergyPvDataModel
     */
    pV2home?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface EventResponseModel
 */
export interface EventResponseModel {
    /**
     * 
     * @type {EventTopicResponseModel}
     * @memberof EventResponseModel
     */
    xEvents?: EventTopicResponseModel;
}

/**
 * 
 * @export
 * @interface EventTopicResponseModel
 */
export interface EventTopicResponseModel {
    /**
     * 
     * @type {WallBoxResponseModel}
     * @memberof EventTopicResponseModel
     */
    wallboxTelemetries?: WallBoxResponseModel;
    /**
     * 
     * @type {PvBatteryItemsResponseModel}
     * @memberof EventTopicResponseModel
     */
    pvbatteryTelemetries?: PvBatteryItemsResponseModel;
    /**
     * 
     * @type {GcpResponseModel}
     * @memberof EventTopicResponseModel
     */
    gcpTelemetries?: GcpResponseModel;
    /**
     * 
     * @type {DhwDataResponseModel}
     * @memberof EventTopicResponseModel
     */
    dhwTelemetries?: DhwDataResponseModel;
    /**
     * 
     * @type {CompressorResponseModel}
     * @memberof EventTopicResponseModel
     */
    compressorTelemetries?: CompressorResponseModel;
    /**
     * 
     * @type {HvacResponseModel}
     * @memberof EventTopicResponseModel
     */
    hvacTelemetries?: HvacResponseModel;
    /**
     * 
     * @type {SmartHomeResponseModel}
     * @memberof EventTopicResponseModel
     */
    smarthomeTelemetries?: SmartHomeResponseModel;
    /**
     * 
     * @type {EventsResponseModel}
     * @memberof EventTopicResponseModel
     */
    notifications?: EventsResponseModel;
    /**
     * 
     * @type {GatewayStatusResponseModel}
     * @memberof EventTopicResponseModel
     */
    gatewayStatuses?: GatewayStatusResponseModel;
}

/**
 * 
 * @export
 * @interface EventsResponseModel
 */
export interface EventsResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof EventsResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof EventsResponseModel
     */
    creationTime?: number;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof EventsResponseModel
     */
    siteId?: string;
    /**
     * Message identifier. Used for acknowledging the message.
     * @type {string}
     * @memberof EventsResponseModel
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsResponseModel
     */
    messageType?: EventsResponseModel.MessageTypeEnum;
    /**
     * Optional Property
     * @type {string}
     * @memberof EventsResponseModel
     */
    messageSubType?: EventsResponseModel.MessageSubTypeEnum;
    /**
     * Optional Property for message type `Alert`
     * @type {string}
     * @memberof EventsResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {any}
     * @memberof EventsResponseModel
     */
    deviceType?: any;
    /**
     * 
     * @type {any}
     * @memberof EventsResponseModel
     */
    data?: any;
}

/**
 * @export
 * @namespace EventsResponseModel
 */
export namespace EventsResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum MessageTypeEnum {
        Notification = <any> 'Notification',
        Alert = <any> 'Alert'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MessageSubTypeEnum {
        HvacPowerFailed = <any> 'hvac_power_failed',
        HvacTemperatureFailed = <any> 'hvac_temperature_failed',
        HvacStateChange = <any> 'hvac_state_change',
        EvseCurrentModulationFailed = <any> 'evse_current_modulation_failed',
        EvseCurrentModulation = <any> 'evse_current_modulation',
        EvseChargingSessionStateChange = <any> 'evse_charging_session_state_change',
        EvseChargingSessionStateFaulted = <any> 'evse_charging_session_state_faulted',
        EvseChargingModeChange = <any> 'evse_charging_mode_change',
        StationPresence = <any> 'station_presence',
        SmartHomeStateChange = <any> 'smart_home_state_change'
    }
}

/**
 * 
 * @export
 * @interface EvseChargingModeChange
 */
export interface EvseChargingModeChange extends EventsResponseModel {
    /**
     * 
     * @type {EvseChargingModeChangeData}
     * @memberof EvseChargingModeChange
     */
    data?: EvseChargingModeChangeData;
    /**
     * 
     * @type {string}
     * @memberof EvseChargingModeChange
     */
    deviceType?: EvseChargingModeChange.DeviceTypeEnum;
}

/**
 * @export
 * @namespace EvseChargingModeChange
 */
export namespace EvseChargingModeChange {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        EV = <any> 'EV'
    }
}

/**
 * Wall Box - Charging Mode | **messageType** `Alert`
 * @export
 * @interface EvseChargingModeChangeData
 */
export interface EvseChargingModeChangeData {
    /**
     * Status Event
     * @type {string}
     * @memberof EvseChargingModeChangeData
     */
    status?: EvseChargingModeChangeData.StatusEnum;
}

/**
 * @export
 * @namespace EvseChargingModeChangeData
 */
export namespace EvseChargingModeChangeData {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Start = <any> 'start',
        Stop = <any> 'stop'
    }
}

/**
 * 
 * @export
 * @interface EvseChargingSessionStateChange
 */
export interface EvseChargingSessionStateChange extends EventsResponseModel {
    /**
     * 
     * @type {EvseChargingSessionStateChangeData}
     * @memberof EvseChargingSessionStateChange
     */
    data?: EvseChargingSessionStateChangeData;
    /**
     * 
     * @type {string}
     * @memberof EvseChargingSessionStateChange
     */
    deviceType?: EvseChargingSessionStateChange.DeviceTypeEnum;
}

/**
 * @export
 * @namespace EvseChargingSessionStateChange
 */
export namespace EvseChargingSessionStateChange {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        EV = <any> 'EV'
    }
}

/**
 * Wall Box - Charging Session | **messageType** `Alert`
 * @export
 * @interface EvseChargingSessionStateChangeData
 */
export interface EvseChargingSessionStateChangeData {
    /**
     * Status Event
     * @type {string}
     * @memberof EvseChargingSessionStateChangeData
     */
    status?: EvseChargingSessionStateChangeData.StatusEnum;
}

/**
 * @export
 * @namespace EvseChargingSessionStateChangeData
 */
export namespace EvseChargingSessionStateChangeData {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Charging = <any> 'Charging',
        Faulted = <any> 'Faulted',
        Finishing = <any> 'Finishing',
        Available = <any> 'Available',
        Preparing = <any> 'Preparing',
        SuspendedEV = <any> 'SuspendedEV',
        SuspendedEVSE = <any> 'SuspendedEVSE',
        StartFailed = <any> 'start_failed',
        StopFailed = <any> 'stop_failed',
        StationInUse = <any> 'station_in_use'
    }
}

/**
 * 
 * @export
 * @interface EvseCurrentModulation
 */
export interface EvseCurrentModulation extends EventsResponseModel {
    /**
     * 
     * @type {EvseCurrentModulationFailedData}
     * @memberof EvseCurrentModulation
     */
    data?: EvseCurrentModulationFailedData;
    /**
     * 
     * @type {string}
     * @memberof EvseCurrentModulation
     */
    deviceType?: EvseCurrentModulation.DeviceTypeEnum;
}

/**
 * @export
 * @namespace EvseCurrentModulation
 */
export namespace EvseCurrentModulation {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        EV = <any> 'EV'
    }
}

/**
 * 
 * @export
 * @interface EvseCurrentModulationFailed
 */
export interface EvseCurrentModulationFailed extends EventsResponseModel {
    /**
     * 
     * @type {EvseCurrentModulationFailedData}
     * @memberof EvseCurrentModulationFailed
     */
    data?: EvseCurrentModulationFailedData;
    /**
     * 
     * @type {string}
     * @memberof EvseCurrentModulationFailed
     */
    deviceType?: EvseCurrentModulationFailed.DeviceTypeEnum;
}

/**
 * @export
 * @namespace EvseCurrentModulationFailed
 */
export namespace EvseCurrentModulationFailed {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        EV = <any> 'EV'
    }
}

/**
 * Wall Box - Current Modulation | **messageType** `Alert`
 * @export
 * @interface EvseCurrentModulationFailedData
 */
export interface EvseCurrentModulationFailedData {
    /**
     * 
     * @type {string}
     * @memberof EvseCurrentModulationFailedData
     */
    status?: string;
}

/**
 * 
 * @export
 * @interface GatewayStatusResponseModel
 */
export interface GatewayStatusResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof GatewayStatusResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof GatewayStatusResponseModel
     */
    creationTime?: number;
    /**
     * Internal ID of the device
     * @type {string}
     * @memberof GatewayStatusResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayStatusResponseModel
     */
    deviceType?: GatewayStatusResponseModel.DeviceTypeEnum;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof GatewayStatusResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayStatusResponseModel
     */
    deviceLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayStatusResponseModel
     */
    level?: GatewayStatusResponseModel.LevelEnum;
}

/**
 * @export
 * @namespace GatewayStatusResponseModel
 */
export namespace GatewayStatusResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        Inverter = <any> 'Inverter',
        Meter = <any> 'Meter'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LevelEnum {
        Ok = <any> 'ok',
        Alerting = <any> 'alerting',
        NoData = <any> 'no_data',
        Warning = <any> 'warning'
    }
}

/**
 * 
 * @export
 * @interface GcpDataEnergyResponseModel
 */
export interface GcpDataEnergyResponseModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof GcpDataEnergyResponseModel
     */
    home2grid?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof GcpDataEnergyResponseModel
     */
    grid2home?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof GcpDataEnergyResponseModel
     */
    consumption?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof GcpDataEnergyResponseModel
     */
    selfConsumption?: TotalDataModel;
    /**
     * 
     * @type {SolarCloudModel}
     * @memberof GcpDataEnergyResponseModel
     */
    solarCloud?: SolarCloudModel;
}

/**
 * 
 * @export
 * @interface GcpDataResponseModel
 */
export interface GcpDataResponseModel {
    /**
     * 
     * @type {PhaseModel}
     * @memberof GcpDataResponseModel
     */
    current?: PhaseModel;
    /**
     * 
     * @type {TotalConsumptionDataModel}
     * @memberof GcpDataResponseModel
     */
    power?: TotalConsumptionDataModel;
    /**
     * 
     * @type {GcpDataEnergyResponseModel}
     * @memberof GcpDataResponseModel
     */
    energy?: GcpDataEnergyResponseModel;
}

/**
 * 
 * @export
 * @interface GcpResponseModel
 */
export interface GcpResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof GcpResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof GcpResponseModel
     */
    creationTime?: number;
    /**
     * 
     * @type {string}
     * @memberof GcpResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof GcpResponseModel
     */
    deviceType?: GcpResponseModel.DeviceTypeEnum;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof GcpResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {GcpDataResponseModel}
     * @memberof GcpResponseModel
     */
    data?: GcpDataResponseModel;
}

/**
 * @export
 * @namespace GcpResponseModel
 */
export namespace GcpResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        Meter = <any> 'Meter'
    }
}

/**
 * 
 * @export
 * @interface GenericError
 */
export interface GenericError {
    /**
     * The error name
     * @type {string}
     * @memberof GenericError
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof GenericError
     */
    message: string;
}

/**
 * 
 * @export
 * @interface HvacCommand
 */
export interface HvacCommand extends EventsResponseModel {
    /**
     * 
     * @type {HvacCommandData}
     * @memberof HvacCommand
     */
    data?: HvacCommandData;
    /**
     * 
     * @type {string}
     * @memberof HvacCommand
     */
    deviceType?: HvacCommand.DeviceTypeEnum;
}

/**
 * @export
 * @namespace HvacCommand
 */
export namespace HvacCommand {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        HEATING = <any> 'HEATING',
        AIRCONDITIONING = <any> 'AIR_CONDITIONING',
        HOTWATER = <any> 'HOT_WATER'
    }
}

/**
 * HVAC - Events | **messageType** `Alert`
 * @export
 * @interface HvacCommandData
 */
export interface HvacCommandData {
    /**
     * 
     * @type {string}
     * @memberof HvacCommandData
     */
    status?: HvacCommandData.StatusEnum;
}

/**
 * @export
 * @namespace HvacCommandData
 */
export namespace HvacCommandData {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PowerFailed = <any> 'hvac_power_failed',
        TemperatureFailed = <any> 'hvac_temperature_failed',
        ModeFailed = <any> 'hvac_mode_failed',
        SwingFailed = <any> 'hvac_swing_failed',
        FanSpeedFailed = <any> 'hvac_fanSpeed_failed'
    }
}

/**
 * 
 * @export
 * @interface HvacResponseModel
 */
export interface HvacResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof HvacResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof HvacResponseModel
     */
    creationTime?: number;
    /**
     * 
     * @type {string}
     * @memberof HvacResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof HvacResponseModel
     */
    deviceType?: HvacResponseModel.DeviceTypeEnum;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof HvacResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {HvacTelemetryResponseModel}
     * @memberof HvacResponseModel
     */
    data?: HvacTelemetryResponseModel;
}

/**
 * @export
 * @namespace HvacResponseModel
 */
export namespace HvacResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        HEATING = <any> 'HEATING',
        AIRCONDITIONING = <any> 'AIR_CONDITIONING'
    }
}

/**
 * 
 * @export
 * @interface HvacStateChange
 */
export interface HvacStateChange extends EventsResponseModel {
    /**
     * 
     * @type {HvacStateChangeData}
     * @memberof HvacStateChange
     */
    data?: HvacStateChangeData;
    /**
     * 
     * @type {string}
     * @memberof HvacStateChange
     */
    deviceType?: HvacStateChange.DeviceTypeEnum;
}

/**
 * @export
 * @namespace HvacStateChange
 */
export namespace HvacStateChange {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        HEATING = <any> 'HEATING',
        AIRCONDITIONING = <any> 'AIR_CONDITIONING',
        HOTWATER = <any> 'HOT_WATER'
    }
}

/**
 * HVAC - StateChange | **messageType** `Alert`
 * @export
 * @interface HvacStateChangeData
 */
export interface HvacStateChangeData {
    /**
     * 
     * @type {HvacStateChangeDataTemperature}
     * @memberof HvacStateChangeData
     */
    temperature?: HvacStateChangeDataTemperature;
    /**
     * 
     * @type {string}
     * @memberof HvacStateChangeData
     */
    state?: HvacStateChangeData.StateEnum;
    /**
     * 
     * @type {string}
     * @memberof HvacStateChangeData
     */
    power?: HvacStateChangeData.PowerEnum;
    /**
     * Only for **deviceType** `AIR_CONDITIONING`
     * @type {string}
     * @memberof HvacStateChangeData
     */
    fanSpeed?: HvacStateChangeData.FanSpeedEnum;
    /**
     * Only for **deviceType** `AIR_CONDITIONING`
     * @type {string}
     * @memberof HvacStateChangeData
     */
    swing?: HvacStateChangeData.SwingEnum;
    /**
     * Only for **deviceType** `AIR_CONDITIONING`
     * @type {string}
     * @memberof HvacStateChangeData
     */
    mode?: HvacStateChangeData.ModeEnum;
}

/**
 * @export
 * @namespace HvacStateChangeData
 */
export namespace HvacStateChangeData {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        ONLINE = <any> 'ONLINE',
        OFFLINE = <any> 'OFFLINE',
        UNKNOWN = <any> 'UNKNOWN'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PowerEnum {
        ON = <any> 'ON',
        OFF = <any> 'OFF'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum FanSpeedEnum {
        LOW = <any> 'LOW',
        MIDDLE = <any> 'MIDDLE',
        HIGH = <any> 'HIGH',
        AUTO = <any> 'AUTO'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SwingEnum {
        ON = <any> 'ON',
        OFF = <any> 'OFF'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ModeEnum {
        COOL = <any> 'COOL',
        HEAT = <any> 'HEAT'
    }
}

/**
 * 
 * @export
 * @interface HvacStateChangeDataTemperature
 */
export interface HvacStateChangeDataTemperature {
    /**
     * 21.2
     * @type {number}
     * @memberof HvacStateChangeDataTemperature
     */
    celsius?: number;
    /**
     * 70.16
     * @type {number}
     * @memberof HvacStateChangeDataTemperature
     */
    fahrenheit?: number;
}

/**
 * 
 * @export
 * @interface HvacTelemetryResponseModel
 */
export interface HvacTelemetryResponseModel {
    /**
     * 
     * @type {HvacTelemetryTemperatureResponseModel}
     * @memberof HvacTelemetryResponseModel
     */
    temperature?: HvacTelemetryTemperatureResponseModel;
}

/**
 * 
 * @export
 * @interface HvacTelemetryTemperatureResponseModel
 */
export interface HvacTelemetryTemperatureResponseModel {
    /**
     * 
     * @type {number}
     * @memberof HvacTelemetryTemperatureResponseModel
     */
    celsius?: number;
    /**
     * 
     * @type {number}
     * @memberof HvacTelemetryTemperatureResponseModel
     */
    fahrenheit?: number;
}

/**
 * 
 * @export
 * @interface InverterDataModel
 */
export interface InverterDataModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof InverterDataModel
     */
    power?: TotalDataModel;
    /**
     * 
     * @type {InverterEnegeryDataModel}
     * @memberof InverterDataModel
     */
    energy?: InverterEnegeryDataModel;
}

/**
 * 
 * @export
 * @interface InverterEnegeryDataModel
 */
export interface InverterEnegeryDataModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof InverterEnegeryDataModel
     */
    _in?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof InverterEnegeryDataModel
     */
    out?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface PhaseModel
 */
export interface PhaseModel {
    /**
     * 
     * @type {number}
     * @memberof PhaseModel
     */
    l1?: number;
    /**
     * 
     * @type {number}
     * @memberof PhaseModel
     */
    l2?: number;
    /**
     * 
     * @type {number}
     * @memberof PhaseModel
     */
    l3?: number;
}

/**
 * 
 * @export
 * @interface PvBatteryDataResponseModel
 */
export interface PvBatteryDataResponseModel {
    /**
     * 
     * @type {PvDataModel}
     * @memberof PvBatteryDataResponseModel
     */
    PV?: PvDataModel;
    /**
     * 
     * @type {BatteryDataModel}
     * @memberof PvBatteryDataResponseModel
     */
    battery?: BatteryDataModel;
    /**
     * 
     * @type {InverterDataModel}
     * @memberof PvBatteryDataResponseModel
     */
    inverter?: InverterDataModel;
}

/**
 * 
 * @export
 * @interface PvBatteryItemsResponseModel
 */
export interface PvBatteryItemsResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof PvBatteryItemsResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof PvBatteryItemsResponseModel
     */
    creationTime?: number;
    /**
     * 
     * @type {string}
     * @memberof PvBatteryItemsResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PvBatteryItemsResponseModel
     */
    deviceType?: PvBatteryItemsResponseModel.DeviceTypeEnum;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof PvBatteryItemsResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {PvBatteryDataResponseModel}
     * @memberof PvBatteryItemsResponseModel
     */
    data?: PvBatteryDataResponseModel;
}

/**
 * @export
 * @namespace PvBatteryItemsResponseModel
 */
export namespace PvBatteryItemsResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        Inverter = <any> 'Inverter'
    }
}

/**
 * 
 * @export
 * @interface PvDataModel
 */
export interface PvDataModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof PvDataModel
     */
    power?: TotalDataModel;
    /**
     * 
     * @type {EnergyPvDataModel}
     * @memberof PvDataModel
     */
    energy?: EnergyPvDataModel;
}

/**
 * 
 * @export
 * @interface SessionDataModel
 */
export interface SessionDataModel {
    /**
     * 
     * @type {number}
     * @memberof SessionDataModel
     */
    session?: number;
}

/**
 * 
 * @export
 * @interface SmartHomeCommand
 */
export interface SmartHomeCommand extends EventsResponseModel {
    /**
     * 
     * @type {SmartHomeCommandData}
     * @memberof SmartHomeCommand
     */
    data?: SmartHomeCommandData;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeCommand
     */
    deviceType?: SmartHomeCommand.DeviceTypeEnum;
}

/**
 * @export
 * @namespace SmartHomeCommand
 */
export namespace SmartHomeCommand {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        SOCKET = <any> 'SOCKET',
        SWITCH = <any> 'SWITCH',
        LB = <any> 'LB'
    }
}

/**
 * Smart Home - Command | **messageType** `Alert`
 * @export
 * @interface SmartHomeCommandData
 */
export interface SmartHomeCommandData {
    /**
     * 
     * @type {string}
     * @memberof SmartHomeCommandData
     */
    status?: SmartHomeCommandData.StatusEnum;
}

/**
 * @export
 * @namespace SmartHomeCommandData
 */
export namespace SmartHomeCommandData {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        SwitchSuccess = <any> 'switch_success',
        DimLevelSuccess = <any> 'dimLevel_success',
        SwitchFailed = <any> 'switch_failed',
        DimLevelFailed = <any> 'dimLevel_failed'
    }
}

/**
 * 
 * @export
 * @interface SmartHomeResponseModel
 */
export interface SmartHomeResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof SmartHomeResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof SmartHomeResponseModel
     */
    creationTime?: number;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeResponseModel
     */
    deviceType?: SmartHomeResponseModel.DeviceTypeEnum;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof SmartHomeResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {SmartHomeTelemetryResponseModel}
     * @memberof SmartHomeResponseModel
     */
    data?: SmartHomeTelemetryResponseModel;
}

/**
 * @export
 * @namespace SmartHomeResponseModel
 */
export namespace SmartHomeResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        SOCKET = <any> 'SOCKET',
        SWITCH = <any> 'SWITCH',
        LB = <any> 'LB',
        ENERGYMONITOR = <any> 'ENERGY_MONITOR'
    }
}

/**
 * 
 * @export
 * @interface SmartHomeStateChange
 */
export interface SmartHomeStateChange extends EventsResponseModel {
    /**
     * 
     * @type {SmartHomeStateChangeData}
     * @memberof SmartHomeStateChange
     */
    data?: SmartHomeStateChangeData;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeStateChange
     */
    deviceType?: SmartHomeStateChange.DeviceTypeEnum;
}

/**
 * @export
 * @namespace SmartHomeStateChange
 */
export namespace SmartHomeStateChange {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        SOCKET = <any> 'SOCKET',
        SWITCH = <any> 'SWITCH',
        LB = <any> 'LB'
    }
}

/**
 * Smart Home - Device State Change | **messageType** `Alert`
 * @export
 * @interface SmartHomeStateChangeData
 */
export interface SmartHomeStateChangeData {
    /**
     * 
     * @type {boolean}
     * @memberof SmartHomeStateChangeData
     */
    _switch?: boolean;
    /**
     * applicable if deviceType is `socket` and device has the capability
     * @type {boolean}
     * @memberof SmartHomeStateChangeData
     */
    outletInUse?: boolean;
    /**
     * applicable if deviceType is `switch` or `lb` and device has the capability
     * @type {number}
     * @memberof SmartHomeStateChangeData
     */
    dimLevel?: number;
}

/**
 * 
 * @export
 * @interface SmartHomeTelemetryResponseModel
 */
export interface SmartHomeTelemetryResponseModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof SmartHomeTelemetryResponseModel
     */
    power?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof SmartHomeTelemetryResponseModel
     */
    energy?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface SolarCloudModel
 */
export interface SolarCloudModel {
    /**
     * 
     * @type {number}
     * @memberof SolarCloudModel
     */
    balance?: number;
    /**
     * 
     * @type {any}
     * @memberof SolarCloudModel
     */
    readingTimestamp?: any;
}

/**
 * 
 * @export
 * @interface StationPresence
 */
export interface StationPresence extends EventsResponseModel {
    /**
     * 
     * @type {StationPresenceData}
     * @memberof StationPresence
     */
    data?: StationPresenceData;
    /**
     * 
     * @type {string}
     * @memberof StationPresence
     */
    deviceType?: StationPresence.DeviceTypeEnum;
}

/**
 * @export
 * @namespace StationPresence
 */
export namespace StationPresence {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        EV = <any> 'EV'
    }
}

/**
 * Wall Box - Presence | **messageType** `Alert`
 * @export
 * @interface StationPresenceData
 */
export interface StationPresenceData {
    /**
     * Online status of the wall box
     * @type {boolean}
     * @memberof StationPresenceData
     */
    online?: boolean;
    /**
     * Charging status of the wall box
     * @type {boolean}
     * @memberof StationPresenceData
     */
    charging?: boolean;
}

/**
 * 
 * @export
 * @interface SystemHealthModel
 */
export interface SystemHealthModel {
    /**
     * 
     * @type {string}
     * @memberof SystemHealthModel
     */
    status?: SystemHealthModel.StatusEnum;
}

/**
 * @export
 * @namespace SystemHealthModel
 */
export namespace SystemHealthModel {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Available = <any> 'available',
        Unavailable = <any> 'unavailable',
        Degraded = <any> 'degraded'
    }
}

/**
 * 
 * @export
 * @interface TotalConsumptionDataModel
 */
export interface TotalConsumptionDataModel {
    /**
     * 
     * @type {number}
     * @memberof TotalConsumptionDataModel
     */
    total?: number;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof TotalConsumptionDataModel
     */
    consumption?: TotalDataModel;
    /**
     * 
     * @type {TotalDataModel}
     * @memberof TotalConsumptionDataModel
     */
    selfConsumption?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface TotalDataModel
 */
export interface TotalDataModel {
    /**
     * 
     * @type {number}
     * @memberof TotalDataModel
     */
    total?: number;
}

/**
 * 
 * @export
 * @interface Unauthorized
 */
export interface Unauthorized {
    /**
     * The error name
     * @type {string}
     * @memberof Unauthorized
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof Unauthorized
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof Unauthorized
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof Unauthorized
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof Unauthorized
     */
    stack?: string;
}

/**
 * 
 * @export
 * @interface WallBoxResponseModel
 */
export interface WallBoxResponseModel {
    /**
     * Schema / Data model version
     * @type {string}
     * @memberof WallBoxResponseModel
     */
    version?: string;
    /**
     * Unix timestamp in seconds
     * @type {number}
     * @memberof WallBoxResponseModel
     */
    creationTime?: number;
    /**
     * 
     * @type {string}
     * @memberof WallBoxResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxResponseModel
     */
    deviceType?: WallBoxResponseModel.DeviceTypeEnum;
    /**
     * Uuid of the installation site
     * @type {string}
     * @memberof WallBoxResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {WallBoxTelemetryResponseModel}
     * @memberof WallBoxResponseModel
     */
    data?: WallBoxTelemetryResponseModel;
}

/**
 * @export
 * @namespace WallBoxResponseModel
 */
export namespace WallBoxResponseModel {
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceTypeEnum {
        EV = <any> 'EV'
    }
}

/**
 * 
 * @export
 * @interface WallBoxTelemetryCustomModel
 */
export interface WallBoxTelemetryCustomModel {
    /**
     * 
     * @type {number}
     * @memberof WallBoxTelemetryCustomModel
     */
    durationSeconds?: number;
    /**
     * 
     * @type {WallBoxTelemetryEnergyCustomModel}
     * @memberof WallBoxTelemetryCustomModel
     */
    energy?: WallBoxTelemetryEnergyCustomModel;
}

/**
 * 
 * @export
 * @interface WallBoxTelemetryEnergyCustomModel
 */
export interface WallBoxTelemetryEnergyCustomModel {
    /**
     * 
     * @type {TotalDataModel}
     * @memberof WallBoxTelemetryEnergyCustomModel
     */
    home2EV?: TotalDataModel;
}

/**
 * 
 * @export
 * @interface WallBoxTelemetryEnergyModel
 */
export interface WallBoxTelemetryEnergyModel {
    /**
     * 
     * @type {SessionDataModel}
     * @memberof WallBoxTelemetryEnergyModel
     */
    home2EV?: SessionDataModel;
}

/**
 * 
 * @export
 * @interface WallBoxTelemetryPowerModel
 */
export interface WallBoxTelemetryPowerModel {
    /**
     * 
     * @type {SessionDataModel}
     * @memberof WallBoxTelemetryPowerModel
     */
    home2EV?: SessionDataModel;
}

/**
 * 
 * @export
 * @interface WallBoxTelemetryResponseModel
 */
export interface WallBoxTelemetryResponseModel {
    /**
     * 
     * @type {number}
     * @memberof WallBoxTelemetryResponseModel
     */
    chargingSessionID?: number;
    /**
     * 
     * @type {WallBoxTelemetryPowerModel}
     * @memberof WallBoxTelemetryResponseModel
     */
    power?: WallBoxTelemetryPowerModel;
    /**
     * 
     * @type {WallBoxTelemetryEnergyModel}
     * @memberof WallBoxTelemetryResponseModel
     */
    energy?: WallBoxTelemetryEnergyModel;
    /**
     * 
     * @type {WallBoxTelemetryCustomModel}
     * @memberof WallBoxTelemetryResponseModel
     */
    hemsCustomValues?: WallBoxTelemetryCustomModel;
}


/**
 * StreamApi - fetch parameter creator
 * @export
 */
export const StreamApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use the model as a description of possible events under `x-events` (e.g. wallbox-telemetries) and follow the data model inside.
         * @summary Get SSE subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSubscribe(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/stream`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StreamApi - functional programming interface
 * @export
 */
export const StreamApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Use the model as a description of possible events under `x-events` (e.g. wallbox-telemetries) and follow the data model inside.
         * @summary Get SSE subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSubscribe(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EventResponseModel> {
            const localVarFetchArgs = StreamApiFetchParamCreator(configuration).streamSubscribe(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StreamApi - factory interface
 * @export
 */
export const StreamApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Use the model as a description of possible events under `x-events` (e.g. wallbox-telemetries) and follow the data model inside.
         * @summary Get SSE subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamSubscribe(options?: any) {
            return StreamApiFp(configuration).streamSubscribe(options)(fetch, basePath);
        },
    };
};

/**
 * StreamApi - object-oriented interface
 * @export
 * @class StreamApi
 * @extends {BaseAPI}
 */
export class StreamApi extends BaseAPI {
    /**
     * Use the model as a description of possible events under `x-events` (e.g. wallbox-telemetries) and follow the data model inside.
     * @summary Get SSE subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamApi
     */
    public streamSubscribe(options?: any) {
        return StreamApiFp(this.configuration).streamSubscribe(options)(this.fetch, this.basePath);
    }

}

/**
 * SystemHealthApi - fetch parameter creator
 * @export
 */
export const SystemHealthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrive overall system health status
         * @summary Get system health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemHealthGetHealth(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemHealthApi - functional programming interface
 * @export
 */
export const SystemHealthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Retrive overall system health status
         * @summary Get system health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemHealthGetHealth(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemHealthModel> {
            const localVarFetchArgs = SystemHealthApiFetchParamCreator(configuration).systemHealthGetHealth(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SystemHealthApi - factory interface
 * @export
 */
export const SystemHealthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Retrive overall system health status
         * @summary Get system health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemHealthGetHealth(options?: any) {
            return SystemHealthApiFp(configuration).systemHealthGetHealth(options)(fetch, basePath);
        },
    };
};

/**
 * SystemHealthApi - object-oriented interface
 * @export
 * @class SystemHealthApi
 * @extends {BaseAPI}
 */
export class SystemHealthApi extends BaseAPI {
    /**
     * Retrive overall system health status
     * @summary Get system health status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemHealthApi
     */
    public systemHealthGetHealth(options?: any) {
        return SystemHealthApiFp(this.configuration).systemHealthGetHealth(options)(this.fetch, this.basePath);
    }

}

/**
 * SystemHealthInternalApi - fetch parameter creator
 * @export
 */
export const SystemHealthInternalApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrive overall system health status
         * @summary Advanced system health service, only used internal for the load balancer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemHealthInternalGetHealth(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/health_internal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemHealthInternalApi - functional programming interface
 * @export
 */
export const SystemHealthInternalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Retrive overall system health status
         * @summary Advanced system health service, only used internal for the load balancer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemHealthInternalGetHealth(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemHealthModel> {
            const localVarFetchArgs = SystemHealthInternalApiFetchParamCreator(configuration).systemHealthInternalGetHealth(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SystemHealthInternalApi - factory interface
 * @export
 */
export const SystemHealthInternalApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Retrive overall system health status
         * @summary Advanced system health service, only used internal for the load balancer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemHealthInternalGetHealth(options?: any) {
            return SystemHealthInternalApiFp(configuration).systemHealthInternalGetHealth(options)(fetch, basePath);
        },
    };
};

/**
 * SystemHealthInternalApi - object-oriented interface
 * @export
 * @class SystemHealthInternalApi
 * @extends {BaseAPI}
 */
export class SystemHealthInternalApi extends BaseAPI {
    /**
     * Retrive overall system health status
     * @summary Advanced system health service, only used internal for the load balancer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemHealthInternalApi
     */
    public systemHealthInternalGetHealth(options?: any) {
        return SystemHealthInternalApiFp(this.configuration).systemHealthInternalGetHealth(options)(this.fetch, this.basePath);
    }

}

