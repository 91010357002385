import { useAppSelector } from '@store/selectors';
import { GatewayInverterKindsResponseModelKindEnum } from '@swagger-http';

export const useHasGcp = () => useAppSelector((state) => state.energy.hasGCP);

export const useHasSolarCloud = () =>
    useAppSelector((state) => state.energy.hasSolarCloud);

export const useHasInverter = () =>
    useAppSelector((state) => state.energy.hasInverter);

export const useHasSmartMeter = () =>
    useAppSelector((state) => state.energy.hasSmartMeter);

export const useHasUkSmartMeter = () =>
    useAppSelector((state) => state.energy.hasUkSmartMeter);

export const useHasBattery = () =>
    useAppSelector((state) => state.energy.hasBattery);

export const useHasGasMeter = () =>
    useAppSelector((state) => state.energy.hasGasMeter);

export const useHasElectricityMeter = () =>
    useAppSelector((state) => state.energy.hasElectricityMeter);

export const useEnergyProvider = () =>
    useAppSelector((state) => state.energy.provider);

export const useLoggedIn = () => !!useAppSelector((state) => state.auth.token);

export const usePvPeak = (): number =>
    useAppSelector((state) => state.energy.pvPeakPower);

export const useHasUnknownInverterType = () =>
    useAppSelector(
        (state) =>
            !!state.energy.inverterTypes?.find(
                (inverter) =>
                    inverter.kind ===
                    GatewayInverterKindsResponseModelKindEnum.Unknown,
            ),
    );

export const useInverterTypes = () =>
    useAppSelector((state) => state.energy.inverterTypes);
