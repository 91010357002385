import { webSocket } from '@store/actions';
import { LiveDataState } from './types';
import { TelemetryTypes } from './enums';
import { useHasSmartMeter, useHasGcp, useAppSelector } from '@store/selectors';

type Telemetries = LiveDataState['telemetries'];

// prettier-ignore
export const useTelemetries = <K extends keyof Telemetries>(
    branch: K,
): Telemetries[K] => useAppSelector((state) => state.liveData.telemetries[branch]);

// prettier-ignore
export const useDevicesLiveData = () => useAppSelector((state) => state.liveData.devices);

export const useLiveDataLoading = () => {
    const min = !!webSocket ? 1 : 2;
    const hasGCP = useHasGcp();
    const pvEvents = useTelemetries(
        TelemetryTypes.PV_BATTERY,
    ).telemetriesReceived;
    const gcpEvents = useTelemetries(TelemetryTypes.GCP).telemetriesReceived;
    const hasSmartMeter = useHasSmartMeter();

    if (hasSmartMeter) {
        return pvEvents < min;
    }

    return hasGCP ? pvEvents < min || gcpEvents < min : pvEvents < min;
};
