import thunk, { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Store, createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'; HS-1815

import { rootReducer } from '@store/root-reducer';
import { auth as authMiddleware } from '@store/middlewares/auth';

import { initialState as cms } from '@store/branches/cms/reducer';
import { initialState as user } from '@store/branches/user/reducer';
import { initialState as auth } from '@store/branches/auth/reducer';
import { initialState as tariff } from '@store/branches/tariff/reducer';
import { initialState as energy } from '@store/branches/energy/reducer';
import { initialState as weather } from '@store/branches/weather/reducer';
import { initialState as liveData } from '@store/branches/live-data/reducer';
import { initialState as notifications } from '@store/branches/notifications/reducer';
import { initialState as settings } from '@store/branches/settings/reducer';
import { initialState as insights } from '@store/branches/insights/reducer';
import { initialState as emobility } from '@store/branches/emobility/reducer';
import { initialState as smartHome } from '@store/branches/smart-home/reducer';
import { initialState as spotPrice } from '@store/branches/spot-price/reducer';
import { initialState as historicalData } from '@store/branches/historical-data/reducer';
import { initialState as aggregatedData } from '@store/branches/aggregated-data/reducer';
import { initialState as historicalEnergyFlow } from '@store/branches/energy-flow/reducer';

declare module 'redux' {
    interface Dispatch<A extends Action = AnyAction> {
        <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
    }
}

// Alphabetical order for better dev experience in Redux extension
export const initialState = {
    aggregatedData,
    auth,
    cms,
    emobility,
    energy,
    historicalData,
    // TODO: remove this branch when v2 migration is done
    historicalEnergyFlow,
    insights,
    liveData,
    notifications,
    settings,
    smartHome,
    spotPrice,
    tariff,
    user,
    weather,
};

export const configureStore = (): Store<typeof initialState> =>
    createStore(
        rootReducer(),
        initialState,
        composeWithDevTools(applyMiddleware(authMiddleware, thunk)),
    );
