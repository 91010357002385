import { Locale } from '@eon-home/react-library';

export const enum Language {
    ENGLISH = 'English',
    GERMAN = 'Deutsch',
    SWEDISH = 'Svenska',
    ITALIAN = 'Italiano',
    ROMANIAN = 'Română',
    HUNGARIAN = 'Magyar',
    POLISH = 'Polska',
    DUTCH = 'Dutch',
}

export enum ISO_RegionToRegion {
    'de_DE' = 'DE',
    'en_GB' = 'GB',
    'en_UK' = 'UK',
    'it_IT' = 'IT',
    'sv_SE' = 'SE',
    'hu_HU' = 'HU',
    'pl_PL' = 'PL',
    'nl_NL' = 'NL',
}

export enum UppercaseLocaleToRegion {
    DE = 'DE',
    EN = 'GB',
    IT = 'IT',
    SV = 'SE',
    HU = 'HU',
    PL = 'PL',
    NL = 'NL',
}

const enum CmsExtraLocale {
    EN = 'en',
}

export type ExtendedLocale = Locale & CmsExtraLocale;

export enum CIAM_COUNTRIES {
    // Taken from the backend implementation
    'United Kingdom' = 'en_GB',
    'Germany' = 'de_DE',
    'Italy' = 'it_IT',
    'Sweden' = 'sv_SE',
    'Netherlands' = 'nl_NL',
    'Czech Republic' = 'cz_CZ',
    'en_GB' = 'en_GB',
    'de_DE' = 'de_DE',
    'it_IT' = 'it_IT',
    'sv_SE' = 'sv_SE',
    'nl_NL' = 'nl_NL',
    'hu_HU' = 'hu_HU',
    'cz_CZ' = 'cz_CZ',
    'GB' = 'en_GB',
    'UK' = 'en_UK',
    'DE' = 'de_DE',
    'IT' = 'it_IT',
    'SE' = 'sv_SE',
    'NL' = 'nl_NL',
    'HU' = 'hu_HU',
    'CZ' = 'cz_CZ',
    // Added by the frontend
    'SV' = 'sv_SE',
    'EN' = 'en_GB',
}
