export enum Routes {
    AUTH = '/auth',
    BASE = '/',
    ACTION_REQUIRED = '/action-required',
    CONNECT_HEATING_COOLING = '/connect/heating-cooling',
    CONNECT_ELECTRIC_CAR = '/connect/electric-car',
    CONNECT_WALLBOX = '/connect/emobility', // This stays as "emobility" although it should be "wallbox" because it would have to be changed in the Admin App
    CONNECT_SMART_LIGHTS = '/connect/smart-lights',
    CONNECT_SMART_METER = '/connect/smart-meter',
    CONNECT_SMART_PLUGS = '/connect/smart-sockets',
    CONNECT_SUCCESS = '/account-linking',
    CONNECT_ELECTRIC_CAR_SUCCESS = '/account-linking/electric-car',
    DEVICES = '/devices',
    EMOBILITY = '/emobility',
    EMOBILITY_CHARGING_HISTORY = '/emobility/charging-history',
    ENERGY = '/energy',
    ENERGY_CONSUMPTION = '/energy/consumption',
    ENERGY_GENERATION = '/energy/generation',
    ENERGY_INSTALL_PV = '/energy/install/pv',
    ENERGY_INSTALL_GRIDX = '/energy/install/gridx',
    ENERGY_OVERVIEW = '/energy/overview',
    ENERGY_SOLAR_CLOUD = '/energy/solar-cloud',
    ENERGY_SOLAR_BALANCE = '/energy/solar-balance',
    GOODBYE = '/goodbye',
    INSIGHTS = '/insights',
    INSIGHTS_PV = '/insights/pv',
    INSIGHTS_HEATING = '/insights/heating',
    INSIGHTS_EMOBILITY = '/insights/emobility',
    INSIGHTS_SMART_METER = '/insights/smart-meter',
    MOBILE_REGISTER = '/register/mobile',
    MORE = '/more',
    MORE_PRODUCTS = '/more/products',
    MORE_ACKNOWLEDGEMENT = '/more/acknowledgement',
    MORE_ARCHIVE = '/more/archive',
    MORE_CONTACT = '/more/contact',
    MORE_DATA_DOWNLOAD = '/more/data-download',
    MORE_EMOBILITY_SETTINGS = '/more/emobility-settings',
    MORE_FAQ = '/more/faqs',
    MORE_PARTNERS = '/more/partners',
    MORE_PRIVACY = '/more/privacy-policy',
    MORE_ACCOUNT = '/more/account',
    MORE_TARIFF_RATE_DETAILS = '/more/tariff-rate-details',
    MORE_TARIFF_RATE_HISTORY = '/more/tariff-rate-history',
    MORE_TERMS = '/more/terms-of-use',
    MORE_HOME_PROFILE = '/more/home-profile',
    MORE_REFERRAL_TERMS = '/more/referral-program-terms-and-conditions',
    NOT_FOUND = '*',
    OAUTH = '/oauth',
    REGISTER = '/register',
    SALES = '/sales',
    SMART_METER = '/meter',
    STEPUP_REGISTRATION = '/stepup-registration',
    SURVEY = '/survey',
}

export const PUBLIC_ROUTES: Routes[] = [
    Routes.MORE,
    Routes.MORE_CONTACT,
    Routes.MORE_PRIVACY,
    Routes.MORE_TERMS,
];

export const AGGREGATION_ROUTES: Routes[] = [
    Routes.EMOBILITY,
    Routes.ENERGY_OVERVIEW,
    Routes.ENERGY_CONSUMPTION,
    Routes.ENERGY_GENERATION,
    Routes.ENERGY_SOLAR_CLOUD,
    Routes.ENERGY_SOLAR_BALANCE,
];
