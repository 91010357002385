/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  NotFound,
  SpotPriceModel,
  SpotPriceRegionModel,
  TariffDeleteModelRequest,
  TariffModelRequestItemV2,
  TariffModelResponse,
  TariffModelResponseV2,
  TariffUpdatesTariffRequest,
  Unauthorized,
} from '../models';

export interface TariffCreatesTariffRequest {
    tariffModelRequestItemV2?: TariffModelRequestItemV2;
}

export interface TariffDeleteTariffRequest {
    tariffDeleteModelRequest?: TariffDeleteModelRequest;
}

export interface TariffDeleteTariffByIdRequest {
    id: string;
}

export interface TariffGetSpotPriceRequest {
    fromDate?: string;
    toDate?: string;
}

export interface TariffPutSpotPriceRegionRequest {
    spotPriceRegionModel: SpotPriceRegionModel;
}

export interface TariffUpdatesTariffOperationRequest {
    overwriteExistingValues?: string;
    tariffUpdatesTariffRequest?: TariffUpdatesTariffRequest;
}

export interface TariffUpdatesTariffByIdRequest {
    id: string;
    tariffModelRequestItemV2?: TariffModelRequestItemV2;
}

/**
 * 
 */
export class TariffApi extends runtime.BaseAPI {

    /**
     * Updates the current tariff entry and returns all tariffs of the customer
     * Posts a new tariff for customer
     */
    async tariffCreatesTariffRaw(requestParameters: TariffCreatesTariffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TariffModelResponseV2>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/tariff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tariffModelRequestItemV2,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates the current tariff entry and returns all tariffs of the customer
     * Posts a new tariff for customer
     */
    async tariffCreatesTariff(requestParameters: TariffCreatesTariffRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TariffModelResponseV2>> {
        const response = await this.tariffCreatesTariffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the set spot price region for this customer
     * Deletes the set spot price region for this customer
     */
    async tariffDeleteSpotPriceRegionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff/spotprices/region`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete the set spot price region for this customer
     * Deletes the set spot price region for this customer
     */
    async tariffDeleteSpotPriceRegion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.tariffDeleteSpotPriceRegionRaw(initOverrides);
        return await response.value();
    }

    /**
     * deletes the current price entry and return a boolean to indicate if deletion was successfull
     * Deletes one customer price entry
     */
    async tariffDeleteTariffRaw(requestParameters: TariffDeleteTariffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TariffModelResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tariffDeleteModelRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * deletes the current price entry and return a boolean to indicate if deletion was successfull
     * Deletes one customer price entry
     */
    async tariffDeleteTariff(requestParameters: TariffDeleteTariffRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TariffModelResponse> {
        const response = await this.tariffDeleteTariffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deletes the current price entry and return a boolean to indicate if deletion was successfull
     * Deletes one customer price entry
     */
    async tariffDeleteTariffByIdRaw(requestParameters: TariffDeleteTariffByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tariffDeleteTariffById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/tariff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * deletes the current price entry and return a boolean to indicate if deletion was successfull
     * Deletes one customer price entry
     */
    async tariffDeleteTariffById(requestParameters: TariffDeleteTariffByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.tariffDeleteTariffByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the spot price for this customers market
     * Get the spot prices for this customers market
     */
    async tariffGetSpotPriceRaw(requestParameters: TariffGetSpotPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpotPriceModel>>> {
        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff/spotprices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Returns the spot price for this customers market
     * Get the spot prices for this customers market
     */
    async tariffGetSpotPrice(requestParameters: TariffGetSpotPriceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpotPriceModel>> {
        const response = await this.tariffGetSpotPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the spot price region set for this customer
     * Get the set spot price region of the customer
     */
    async tariffGetSpotPriceRegionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpotPriceRegionModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff/spotprices/region`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the spot price region set for this customer
     * Get the set spot price region of the customer
     */
    async tariffGetSpotPriceRegion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpotPriceRegionModel> {
        const response = await this.tariffGetSpotPriceRegionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the spot price regions available for this customers market
     * Get the spot price regions available for this customers market
     */
    async tariffGetSpotPriceRegionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpotPriceRegionModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff/spotprices/regions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the spot price regions available for this customers market
     * Get the spot price regions available for this customers market
     */
    async tariffGetSpotPriceRegions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpotPriceRegionModel>> {
        const response = await this.tariffGetSpotPriceRegionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the spot price regions available for this customers market
     * Get the spot price regions available for this customers market
     */
    async tariffGetSpotPriceRegions1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpotPriceRegionModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/tariff/spotprices/regions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the spot price regions available for this customers market
     * Get the spot price regions available for this customers market
     */
    async tariffGetSpotPriceRegions1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpotPriceRegionModel>> {
        const response = await this.tariffGetSpotPriceRegions1Raw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the saved tariff for a customer or the tenants default price
     * Get the tariff price value for this customer
     */
    async tariffGetTariffRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TariffModelResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Returns the saved tariff for a customer or the tenants default price
     * Get the tariff price value for this customer
     */
    async tariffGetTariff(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TariffModelResponse> {
        const response = await this.tariffGetTariffRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the saved tariffs for a customer or the tenants default tariff
     * Get all the tariffs for this customer
     */
    async tariffGetTariff1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TariffModelResponseV2>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/tariff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Returns the saved tariffs for a customer or the tenants default tariff
     * Get all the tariffs for this customer
     */
    async tariffGetTariff1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TariffModelResponseV2>> {
        const response = await this.tariffGetTariff1Raw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the set spot price region for this customer
     * Updates the spot price region for the customer
     */
    async tariffPutSpotPriceRegionRaw(requestParameters: TariffPutSpotPriceRegionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpotPriceRegionModel>> {
        if (requestParameters.spotPriceRegionModel === null || requestParameters.spotPriceRegionModel === undefined) {
            throw new runtime.RequiredError('spotPriceRegionModel','Required parameter requestParameters.spotPriceRegionModel was null or undefined when calling tariffPutSpotPriceRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff/spotprices/region`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.spotPriceRegionModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates the set spot price region for this customer
     * Updates the spot price region for the customer
     */
    async tariffPutSpotPriceRegion(requestParameters: TariffPutSpotPriceRegionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpotPriceRegionModel> {
        const response = await this.tariffPutSpotPriceRegionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the current tariff entry and returns it
     * Puts or update a given tariff 
     */
    async tariffUpdatesTariffRaw(requestParameters: TariffUpdatesTariffOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TariffModelResponse>> {
        const queryParameters: any = {};

        if (requestParameters.overwriteExistingValues !== undefined) {
            queryParameters['overwriteExistingValues'] = requestParameters.overwriteExistingValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/tariff`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tariffUpdatesTariffRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates the current tariff entry and returns it
     * Puts or update a given tariff 
     */
    async tariffUpdatesTariff(requestParameters: TariffUpdatesTariffOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TariffModelResponse> {
        const response = await this.tariffUpdatesTariffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the current tariff entry and returns all tariffs of the customer
     * Updates a given tariff for customer
     */
    async tariffUpdatesTariffByIdRaw(requestParameters: TariffUpdatesTariffByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TariffModelResponseV2>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tariffUpdatesTariffById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/tariff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tariffModelRequestItemV2,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates the current tariff entry and returns all tariffs of the customer
     * Updates a given tariff for customer
     */
    async tariffUpdatesTariffById(requestParameters: TariffUpdatesTariffByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TariffModelResponseV2>> {
        const response = await this.tariffUpdatesTariffByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
