export const enum Scope {
    WEATHER_READ = 'policy/hems/weather:read', // Read weather data
    ME_READ = 'policy/hems/user:read', // Read user data
    ME_WRITE = 'policy/hems/user:write', // Write user data
    TARIFF_READ = 'policy/hems/user/tariff:read', // Read user tariff
    TARIFF_WRITE = 'policy/hems/user/tariff:write', // Write user tariff
    EMOBILITY_BONUS_READ = 'policy/hems/user/bonus:read', // Read user bonus for autopilot charging
    SMARTHOME_PLUGS_READ = 'policy/hems/smarthome/plugs:read', // Read smart plugs data
    SMARTHOME_PLUGS_WRITE = 'policy/hems/smarthome/plugs:write', // Write smart plugs data
    SMARTHOME_PAIRING_READ = 'policy/hems/smarthome/pairing:read', // Read iot data
    SMARTHOME_PAIRING_WRITE = 'policy/hems/smarthome/pairing:write', // Write iot data
    SMARTHOME_LIGHTS_READ = 'policy/hems/smarthome/lights:read', // Read Lighting data
    SMARTHOME_LIGHTS_WRITE = 'policy/hems/smarthome/lights:write', // Write lighting data
    SMARTHOME_HEATINGCOOLING_READ = 'policy/hems/smarthome/heatingCooling:read', // Read heating and cooling data
    SMARTHOME_HEATINGCOOLING_WRITE = 'policy/hems/smarthome/heatingCooling:write', // Write heating and cooling data
    NOTIFICATION_READ = 'policy/hems/notification:read', // Read notifications data
    NOTIFICATION_WRITE = 'policy/hems/notification:write', // Write notifications data
    PVFORECAST_READ = 'policy/hems/forecast/pvforecast:read', // Read PV forecast data
    ENERGYDEVICES_PVB_READ = 'policy/hems/energydevices/pvb:read', // Read pvb data
    ENERGYDEVICES_PVB_WRITE = 'policy/hems/energydevices/pvb:write', // Write pvb data
    ENERGYDEVICES_GCP_READ = 'policy/hems/energydevices/gcp:read', // Read gcp data
    ENERGYDEVICES_ELECTRIC_CAR_READ = 'policy/hems/energydevices/electric-car:read', // Get charging
    ENERGYDEVICES_ELECTRIC_CAR_WRITE = 'policy/hems/energydevices/electric-car:write', // Update ev
    ENERGYDEVICES_EV_READ = 'policy/hems/energydevices/ev:read', // Get charging
    ENERGYDEVICES_EV_WRITE = 'policy/hems/energydevices/ev:write', // Update ev
    ENERGYDEVICES_GAS_READ = 'policy/hems/energydevices/gas:read', // Read gas data
    ENERGYDEVICES_GAS_WRITE = 'policy/hems/energydevices/gas:write', // Write gas data
    ENERGYDEVICES_GATEWAY_READ = 'policy/hems/energydevices/gateway:read', // Read gateway device data
    ENERGYDEVICES_GATEWAY_WRITE = 'policy/hems/energydevices/gateway:write', // Write gateway device data
    CARBONDIOXIDE_AVOIDED_READ = 'policy/hems/carbondioxide/avoided:read', // Read carbon footprint data. How much was avoided
    BENCHMARK_READ = 'policy/hems/insights/benchmark:read', // Read similar homes data
    DISAGGREGATION_READ = 'policy/hems/insights/disaggregation:read', // Read disaggregation data
    // TODO EONFEH-13649: Rename keys to WALLBOX_ ?
    EMOBILITY_SMART_CHARGING_READ = 'policy/hems/ev/smartCharging:read', // Read ev smart charging data
    EMOBILITY_SMART_CHARGING_WRITE = 'policy/hems/ev/smartCharging:write', // Write ev smart charging data
    EMOBILITY_SOLAR_CHARGING_READ = 'policy/hems/ev/solarCharging:read',
    EMOBILITY_SOLAR_CHARGING_WRITE = 'policy/hems/ev/solarCharging:write',
    EMOBILITY_DYNAMIC_PRICE_CHARGING_READ = 'policy/hems/ev/dynamicPriceCharging:read',
    EMOBILITY_DYNAMIC_PRICE_CHARGING_WRITE = 'policy/hems/ev/dynamicPriceCharging:write',
    EMOBILITY_FLEX_CHARGING_READ = 'policy/hems/ev/flexCharging:read',
    EMOBILITY_FLEX_CHARGING_WRITE = 'policy/hems/ev/flexCharging:write',
    EMOBILITY_AUTOPILOT_WRITE = 'policy/hems/ev/autopilot:write',
    EMOBILITY_AUTOPILOT_READ = 'policy/hems/ev/autopilot:read',
    EMOBILITY_BIDIRECTIONAL_CHARGING_READ = 'policy/hems/ev/bidirectionalCharging:read',
    EMOBILITY_BIDIRECTIONAL_CHARGING_WRITE = 'policy/hems/ev/bidirectionalCharging:write',
    EMOBILITY_COMBINED_CHARGING_READ = 'policy/hems/ev/combined-charging:read',
    BILLING_REGION_READ = 'policy/hems/user/billingRegion:read',
    BILLING_REGION_WRITE = 'policy/hems/user/billingRegion:write',
}

export type ScopeType = string[] | Scope[];
