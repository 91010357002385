import { useEffect, useMemo, useState } from 'react';
import { Themes, UI_THEME, brand } from '@eon-home/react-library';

import {
    IotPairingModel,
    SaleTemplateStepModel,
    SaleTemplateStepModelStatusEnum,
} from '@swagger-http';

import { EmobilityVendors } from '@store/enums';
import { GRIDX_SALE_UUIDS } from '@tools/constants';
import { Moment, isHungarianUser } from '@tools/utils';
import { useAppSelector, useHasSales } from '@store/selectors';

export const useAppLoading = () =>
    useAppSelector((state) => state.settings.ui.appLoading);

export const useGetProvider = (
    needle:
        | 'tado'
        | 'lwrf'
        | 'ukmeter'
        | EmobilityVendors.VIRTA
        | EmobilityVendors.ENODE,
) =>
    useAppSelector((state) =>
        state.settings.smartDevices.providers.find(
            (provider: IotPairingModel) => provider.name === needle,
        ),
    );

export const useGetPairedProvider = (
    needle:
        | 'tado'
        | 'lwrf'
        | 'ukmeter'
        | EmobilityVendors.VIRTA
        | EmobilityVendors.ENODE,
) =>
    useAppSelector((state) =>
        state.settings.smartDevices.providers.find(
            (provider: IotPairingModel) =>
                provider.name === needle && provider.paired,
        ),
    );

export const useIsTadoPaired = () => {
    const provider = useGetProvider('tado');
    return Boolean(provider && provider.paired);
};

export const useInvalidSmartMeterUkData = () =>
    useAppSelector((state) => state.settings.ui.invalidSmartMeterUkData);

interface AppDarkMode {
    isDarkMode: boolean;
    hasDarkMode?: boolean;
}

export const useAppDarkMode = (watch: boolean = false): AppDarkMode => {
    const media = window.matchMedia('(prefers-color-scheme: dark)');
    const theme = useAppSelector((state) => state.settings.ui.theme);

    const getInitialTheme = () => {
        const savedTheme = localStorage.getItem(UI_THEME);
        const reference = theme ? theme : savedTheme;

        return reference === Themes.AUTO || !reference
            ? media.matches
            : reference === Themes.DARK;
    };

    const condition = getInitialTheme();
    const [isDarkMode, setIsDarkMode] = useState(condition);

    useEffect(() => {
        if (!watch) {
            return;
        }

        const onChange = (e: MediaQueryListEvent) => {
            setIsDarkMode(e.matches);
        };

        try {
            media.addEventListener('change', onChange);
        } catch (error) {
            media.addListener(onChange);
        }

        return () => {
            try {
                media.removeEventListener('change', onChange);
            } catch (error) {
                media.removeListener(onChange);
            }
        };
    }, [media, watch]);

    useEffect(() => {
        if (theme) {
            localStorage.setItem(UI_THEME, theme);
        }

        // If the Theme config hasDarkmode is false,
        // the theme will be always light.
        brand.config?.hasDarkmode
            ? setIsDarkMode(condition)
            : setIsDarkMode(false);
    }, [condition, theme]);

    useEffect(() => {
        if (watch) {
            document.documentElement.classList.toggle('dark-mode', isDarkMode);
        }
    }, [isDarkMode, watch]);

    return {
        isDarkMode,
        hasDarkMode: brand.config?.hasDarkmode,
    };
};

export const useSales = (isArchive: boolean = false) =>
    useAppSelector(
        (state) => state.settings.sales[isArchive ? 'archive' : 'active'],
    );

export const useHasActiveSales = (): boolean => {
    const active = useSales();
    const hasSales = useHasSales();

    return hasSales && !!active.length;
};
export const useIsGridXCustomer = () => {
    const activeSales = useAppSelector((state) => state.settings.sales.active);
    const archivedSales = useAppSelector(
        (state) => state.settings.sales.archive,
    );
    const sales = useMemo(
        () => [...activeSales, ...archivedSales],
        [activeSales, archivedSales],
    );

    if (!sales.length) {
        return false;
    }

    let isGridXCustomer = false;

    for (const sale of sales) {
        if (GRIDX_SALE_UUIDS.includes(sale.saleTemplate)) {
            isGridXCustomer = true;
            break;
        }
    }

    return isGridXCustomer;
};

export const useSaleDetails = (
    steps: SaleTemplateStepModel[],
    dateFormat: string,
) => {
    const filteredSteps = useMemo(
        () =>
            steps
                ? steps
                      .sort(
                          (
                              a: SaleTemplateStepModel,
                              b: SaleTemplateStepModel,
                          ) =>
                              a.order! > b.order!
                                  ? 1
                                  : a.order! < b.order!
                                  ? -1
                                  : 0,
                      )
                      .filter(
                          (step: SaleTemplateStepModel) =>
                              step.status !==
                              SaleTemplateStepModelStatusEnum.Skipped,
                      )
                : [],
        [steps],
    );
    const visibleSteps = useMemo(
        () =>
            filteredSteps.filter(
                ({ isVisible }: SaleTemplateStepModel) => isVisible,
            ),
        [filteredSteps],
    );
    const lastStep = useMemo(
        () =>
            filteredSteps.find(
                ({ isInstallation }: SaleTemplateStepModel) => isInstallation,
            ),
        [filteredSteps],
    );
    const firstPending = useMemo(
        () =>
            visibleSteps.findIndex(
                ({ status }: SaleTemplateStepModel) =>
                    status === SaleTemplateStepModelStatusEnum.Pending ||
                    status === null,
            ),
        [visibleSteps],
    );
    const date = useMemo(
        () =>
            lastStep?.plannedAt
                ? Moment(lastStep?.plannedAt).format(dateFormat)
                : null,
        [dateFormat, lastStep?.plannedAt],
    );

    return {
        date,
        visibleSteps,
        firstPending,
        filteredSteps,
    };
};

export const useShouldShowHungarianMarketingWidget = (): boolean => {
    const hungarianMarketingSeen = useAppSelector(
        (state) => state.settings.ui.hungarianMarketing08052023,
    );

    if (!isHungarianUser()) {
        return false;
    }

    if (
        !Moment().isBetween(
            Moment('2023-04-10').startOf('day'),
            Moment('2023-05-08').endOf('day'),
            'days',
            '[]',
        )
    ) {
        return false;
    }

    return !hungarianMarketingSeen;
};

export const useToast = () =>
    useAppSelector((state) => state.settings.ui.toast);
