// @ts-ignore
import englishStrings from 'react-timeago/lib/language-strings/en';
// @ts-ignore
import germanStrings from 'react-timeago/lib/language-strings/de';
// @ts-ignore
import italianStrings from 'react-timeago/lib/language-strings/it';
// @ts-ignore
import swedishStrings from 'react-timeago/lib/language-strings/sv';
// @ts-ignore
import polishStrings from 'react-timeago/lib/language-strings/pl';
// @ts-ignore
import dutchStrings from 'react-timeago/lib/language-strings/nl';

import { Locale, Region } from '@eon-home/react-library';

import { getLocale } from '@store/actions';
import { StringOrNull } from '@tools/types';
import { DateTimeFormat } from '@tools/enums';
import { getRegion, localizeNumber } from '@tools/utils';

export const LocalizationConfig: Record<string, any> = {
    defaultLocale: Locale.EN,
    defaultRegion: Region.GB,
    defaultCurrencySymbol: '£',
    regionToIsoLocale: {
        GB: 'en-GB',
        UK: 'en-UK',
        DE: 'de-DE',
        IT: 'it-IT',
        SE: 'sv-SE',
        PL: 'pl-PL',
        HU: 'hu-HU',
        NL: 'nl-NL',
    },
    localeToIsoLocale: {
        en: 'en-GB',
        uk: 'en-GB',
        de: 'de-DE',
        it: 'it-IT',
        sv: 'sv-SE',
        pl: 'pl-PL',
        hu: 'hu-HU',
        nl: 'nl-NL',
    },
    currencyUnitShorthand: {
        GB: '£',
        UK: '£',
        DE: '€',
        IT: '€',
        SE: 'kr',
        PL: 'zł',
        HU: 'Ft',
        NL: '€',
    },
    currencySubunitShorthand: {
        GB: 'p',
        UK: 'p',
        DE: 'c',
        IT: 'c',
        SE: 'ö',
        PL: 'gr',
        // Hungary doesn't have a currency subunit despite what Wikipedia says
        HU: 'Ft',
        NL: 'c',
    },
    currencySymbolToISOCurrency: {
        '£': 'GBP',
        '€': 'EUR',
        kr: 'SEK',
        leu: 'LEU',
        zł: 'PLN',
        Ft: 'HUF',
    },
    dateTimePatterns: {
        en: {
            onlyTime: 'h:mm A',
            onlyTime24: 'H:mm',
            shortDate: 'ddd, D MMMM',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'D MMMM YYYY h:mm A',
            shortMonthAndYear: 'MMM YYYY',
            graphWeek: 'ddd, MMM DD',
            graphMonth: 'MMM DD',
            graphYearTooltip: 'MMM YYYY',
            dateAndTime: 'DD MMM YY, H:mm',
        },
        de: {
            onlyTime: 'H:mm',
            onlyTime24: 'H:mm',
            shortDate: 'ddd, D. MMMM',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'LLL',
            shortMonthAndYear: 'MMM YYYY',
            graphWeek: 'ddd, MMM DD',
            graphMonth: 'MMM DD',
            graphYearTooltip: 'MMM YYYY',
            dateAndTime: 'DD MMM YY, H:mm',
        },
        it: {
            onlyTime: 'H:mm',
            onlyTime24: 'H:mm',
            shortDate: 'ddd D MMMM',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'D MMMM YYYY HH:MM',
            shortMonthAndYear: 'MMM YYYY',
            graphWeek: 'ddd, MMM DD',
            graphMonth: 'MMM DD',
            graphYearTooltip: 'MMM YYYY',
            dateAndTime: 'DD MMM YY, H:mm',
        },
        sv: {
            onlyTime: 'H:mm',
            onlyTime24: 'H:mm',
            shortDate: 'ddd, D MMMM',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'D MMMM YYYY h:mm A',
            shortMonthAndYear: 'MMM YYYY',
            graphWeek: 'ddd, MMM DD',
            graphMonth: 'MMM DD',
            graphYearTooltip: 'MMM YYYY',
            dateAndTime: 'DD MMM YY, H:mm',
        },
        pl: {
            onlyTime: 'H:mm',
            onlyTime24: 'H:mm',
            shortDate: 'ddd, D MMMM',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'D MMMM YYYY h:mm A',
            shortMonthAndYear: 'MMM YYYY',
            graphWeek: 'ddd, MMM DD',
            graphMonth: 'MMM DD',
            graphYearTooltip: 'MMM YYYY',
            dateAndTime: 'DD MMM YY, H:mm',
        },
        hu: {
            onlyTime: 'H:mm',
            onlyTime24: 'H:mm',
            shortDate: 'MMMM D., dddd',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'D MMMM YYYY h:mm A',
            shortMonthAndYear: 'YYYY. MMM',
            graphWeek: 'MMM D., ddd.',
            graphMonth: 'MMM D.',
            graphYearTooltip: 'YYYY. MMMM',
            dateAndTime: 'DD MMM YY, H:mm',
        },
        nl: {
            onlyTime: 'H:mm',
            onlyTime24: 'H:mm',
            shortDate: 'ddd, D. MMMM',
            longDate: 'DD MMM YYYY',
            longDateYearTime: 'LLL',
            shortMonthAndYear: 'MMM YYYY',
            graphWeek: 'ddd, MMM DD',
            graphMonth: 'MMM DD',
            graphYearTooltip: 'MMM YYYY',
            dateAndTime: 'DD MMM YY, H:mm',
        },
    },
    csvDelimiter: {
        en: ',',
        de: ';',
        it: ';',
        sv: ';',
        pl: ';',
        hu: ';',
        nl: ';',
    },
};

const supportedLocales: Map<string, string> = new Map();
const hungarianStrings = {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: null,
    suffixFromNow: null,
    seconds: 'kevesebb, mint egy perce',
    minute: 'körülbelül egy perce',
    minutes: '%d perce',
    hour: 'körülbelül egy órája',
    hours: 'körülbelül %d órája',
    day: 'körülbelül egy napja',
    days: '%d napja',
    month: 'körülbelül egy hónapja',
    months: '%d hónapja',
    year: 'körülbelül egy éve',
    years: '%d éve',
};

enum Currency {
    SEK = 'SEK',
    GBP = 'GBP',
}

Object.keys(Locale).forEach((key: string) => {
    const dict = Locale as unknown as Record<string, string>;

    supportedLocales.set(dict[key], dict[key]);
});

const isoCodeToReplaceWithSymbolMap: Record<Currency, string> = {
    SEK: 'kr',
    GBP: '£',
};

export const getTemperatureUnit = (): string =>
    localStorage.getItem('temperature') || 'C';

export const addSymbolInsteadOfISOCode = (input: string): string => {
    Object.keys(isoCodeToReplaceWithSymbolMap).forEach((key: Currency) => {
        if (input.includes(key)) {
            input = input.replace(key, isoCodeToReplaceWithSymbolMap[key]);
        }
    });

    return input;
};

export const toLocalizedNumber = (
    input: number,
    settings: Intl.NumberFormatOptions = {},
): string => {
    const locale = getLocale();
    const isoLocale = LocalizationConfig.localeToIsoLocale[locale];

    return localizeNumber(input, isoLocale, settings);
};

export const toLocalizedCurrency = (input: number, unit?: string): string => {
    const region = getRegion();
    const locale = getLocale();
    const symbol = unit || LocalizationConfig.currencyUnitShorthand[region];
    const isoLocale = LocalizationConfig.localeToIsoLocale[locale];
    const currency = LocalizationConfig.currencySymbolToISOCurrency[symbol];

    return addSymbolInsteadOfISOCode(
        input.toLocaleString(isoLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
        }),
    );
};

export const toDateTimeFormat = (type: DateTimeFormat): string =>
    LocalizationConfig.dateTimePatterns[getLocale()][type];

export const convertAndFormatTemperature = (
    temperature: number,
    unit?: string,
): string => {
    const temperatureUnit = unit || getTemperatureUnit();

    return temperature.toFixed() + '°' + temperatureUnit;
};

export const getCurrencyUnitShorthand = (): string => {
    const region = getRegion();

    return LocalizationConfig.currencyUnitShorthand[region];
};

export const getCurrencySubunitShorthand = (): string => {
    const region = getRegion();

    return LocalizationConfig.currencySubunitShorthand[region];
};

export const getCSVDelimiter = (index: number, columns: unknown[]): string => {
    const locale = getLocale();
    const csvDelimiter = LocalizationConfig.csvDelimiter[locale];

    return index === columns.length - 1 ? '' : csvDelimiter;
};

export const timeAgoTranslations: Record<
    Locale,
    Record<string, StringOrNull>
> = {
    en: englishStrings,
    de: germanStrings,
    it: italianStrings,
    sv: swedishStrings,
    pl: polishStrings,
    hu: hungarianStrings,
    nl: dutchStrings,
};
