import { Dispatch } from 'redux';

import {
    GCPApi,
    PvBatteryApi,
    ResponseError,
    EfficiencyCheckV2ResponseModel,
    PvBatteryDonutHistoryV2ResponseModelTimelineEnum,
    GasHistoryV2ResponseModel as GasModel,
    ForecastValuesResponseModel as ForecastModel,
    PowerHistoryV2ResponseModel as PowerModel,
    EnergyHistoryV2ResponseModel as EnergyModel,
    EfficiencyCheckV2ResponseModel as EfficiencyCheckModelV2,
    SolarCloudHistoryV2ResponseModel as SolarCloudModel,
    PvBatteryDonutHistoryV2ResponseModel as DonutModel,
    GridConnectionPointHistoryAggregationResponseModel as GCPModel,
    EnergyHistoryV2ResponseModel,
    SolarCloudHistoryV2ResponseModel,
    PowerHistoryV2ResponseModel,
    GasHistoryV2ResponseModel,
} from '@swagger-http';
import { Scope, GraphTypes } from '@tools/enums';
import { Nullable, NumberOrNull } from '@tools/types';
import {
    setHistoricalData,
    getHistoricalWallboxData,
    getHistoricalElectricCarData,
} from '@store/actions';
import {
    IS_NOT_DEV,
    AGGREGATION_THRESHOLD,
    AGGREGATION_TIME_FORMAT,
} from '@tools/constants';
import {
    GraphType,
    ExtendedGraphType,
    AggregatedDataAction,
    HistoricalEmobilityData,
    AggregatedDataModel,
} from '@store/types';
import {
    HistoricalData,
    DonutsDataModel,
    HistoricalDataBranch,
    AggregatedDataUserState,
    AggregatedDataProperties,
    HistoricalDonutDataModel,
    AggregatedDataEnergyState,
    HistoricalDataEnergyFlowBranch,
} from '@store/types';
import {
    Res,
    SolarCloudStatus,
    HistoricalDataTypes,
    HistoricalResolution,
    EnergyFlowActionTypes,
    AggregatedDataActionTypes,
    HistoricalDataActionTypes,
    ExtendedHistoricalResolution,
} from '@store/enums';
import {
    delay,
    Moment,
    isToday,
    handleError,
    getResolution,
    checkForScopes,
    getModifiedDate,
    getRequestDates,
    mapToLocalTimeZone,
    getLastReadingDate,
    extractAggregatedData,
    normalizeForecastData,
    getComparisonInterval,
    createRequestConfiguration,
    checkProductActivationForPeriod,
} from '@tools/utils';

const emptyResponse = [[], []];
// prettier-ignore
const { Currently, _7days, _30days, _365days } = PvBatteryDonutHistoryV2ResponseModelTimelineEnum;

export const clearDataBeforeRegistrationDate = (
    data: any[],
    date: string,
    res: HistoricalResolution,
) => {
    const isYear = res === HistoricalResolution.YEAR;
    const granularity = isYear ? 'month' : 'day';
    const start = Moment(date).startOf(granularity).utc();

    return data.map((item) => {
        if (Moment(item.timestamp).utc().isSameOrAfter(start, granularity)) {
            return item;
        }

        const { timeline, timestamp, ...rest } = item;

        for (const key in rest) {
            rest[key] = null;
        }

        return {
            timeline,
            timestamp,
            ...rest,
        };
    });
};

export const setAggregatedDataLoading = (
    res: HistoricalResolution,
    type: ExtendedGraphType,
): AggregatedDataAction => ({
    type: AggregatedDataActionTypes.LOADING,
    payload: { res, type },
});

export const setAggregatedDataError = (
    res: HistoricalResolution,
    type: ExtendedGraphType,
): AggregatedDataAction => ({
    type: AggregatedDataActionTypes.ERROR,
    payload: { res, type },
});

export const setSliderTouched = (sliderTouched: boolean) => ({
    type: AggregatedDataActionTypes.SET_SLIDER_TOUCHED,
    payload: {
        sliderTouched,
    },
});

export const setSliderValue = (value: NumberOrNull) => ({
    type: AggregatedDataActionTypes.SET_SLIDER_VALUE,
    payload: {
        value,
    },
});

export const setSliderScrollPosition = (scrollPosition: number) => ({
    type: AggregatedDataActionTypes.SET_SLIDER_SCROLL_POSITION,
    payload: {
        scrollPosition,
    },
});

/*
    This function takes care of splitting the response from the backend in two arrays.
    The response from the backend is expected to contain data for two periods (current
    and previous). This function splits them and returns the new array containing two arrays.
*/
export const splitInTwo = <T extends Record<string, any>>(
    data: T[],
    isForecast = false,
): [T[], T[]] => {
    if (!isForecast) {
        return data.reduce(
            (result: [T[], T[]], item: T) => {
                result[item.timeline === Currently ? 0 : 1].push(item);

                return result;
            },
            [[], []],
        );
    }

    const start = 0;
    const { length } = data;
    const middle = Math.floor(length / 2);

    /**
     * Forecast response contains unique values which do not match any
     * of the rest of the endpoints. This is the reason we need to adjust
     * the split function by adding or subtracting a value from the
     * middle of the array in order to achieve the required result.
     */
    const adjustmentStart = !isForecast ? 1 : start;
    const adjustmentEnd = !isForecast ? 0 : start;

    return [
        data.slice(start, middle + adjustmentStart),
        data.slice(middle + adjustmentEnd, length),
    ];
};

export const getPvForecast = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
): Promise<ForecastModel[][]> => {
    if (!checkForScopes([Scope.PVFORECAST_READ])) {
        return emptyResponse;
    }

    const shouldRequestForecast = IS_NOT_DEV
        ? isToday(date, res) && res === HistoricalResolution.DAY1HOUR
        : res === HistoricalResolution.DAY1HOUR ||
          res === HistoricalResolution.WEEK;

    if (!shouldRequestForecast) {
        return emptyResponse;
    }

    const resolution: string = getResolution(res);
    const { startDate, endDate } = getRequestDates(date, res, true);

    const [prevForecastData, forecastData] = await new PvBatteryApi(
        createRequestConfiguration(),
    )
        .pvBatteryGetForecastAggregated1({
            fromDate: startDate,
            toDate: endDate,
            interval: resolution,
        })
        .then((data: ForecastModel[]) => normalizeForecastData(data))
        .then((data: ForecastModel[]) => {
            if (res === HistoricalResolution.WEEK) {
                return data.map((item) => {
                    const { timestamp, ...rest }: any = item;

                    return {
                        ...rest,
                        timestamp: timestamp.replace(
                            /(.*T)(\d+)(:.*)/,
                            // prettier-ignore
                            (_: string, p1: string, __: string, p2: string) => `${p1}00${p2}`,
                        ),
                    };
                });
            }

            return data;
        })
        .then((data: ForecastModel[]) => mapToLocalTimeZone(data, res))
        .then((data: ForecastModel[]) =>
            /**
             * The response from the PVBattery historical endpoint contains a PVpower property
             * That's why we rename the property in the forecast response to avoid overwrites.
             */
            data.map((item: any) => {
                const { values, ...rest } = item;

                return {
                    ...rest,
                    values: {
                        PVpowerOutTotal: values?.PVpower,
                        PVenergyOutTotal: values?.PVenergyOut,
                    },
                };
            }),
        )
        .then((data: ForecastModel[]) =>
            splitInTwo(
                clearDataBeforeRegistrationDate(
                    data,
                    userState.registrationDate,
                    res,
                ),
                true,
            ),
        )
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting pv forecast data:');

            return emptyResponse;
        });

    return [prevForecastData, forecastData];
};

export const fetchPvEfficiency = async (): Promise<
    EfficiencyCheckV2ResponseModel[]
> => {
    const start = Moment().startOf('month');
    const endDate = start;
    const startDate = start.clone().subtract(2, 'months').subtract(0, 'second');

    return await new PvBatteryApi(createRequestConfiguration())
        .pvBatteryGetEfficiencyCheck1({
            fromDate: startDate.format(AGGREGATION_TIME_FORMAT),
            toDate: endDate.format(AGGREGATION_TIME_FORMAT),
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting pv forecast data:');

            return [];
        });
};

export const getPvEfficiencySource = (
    data: EfficiencyCheckModelV2[],
): EfficiencyCheckModelV2 => {
    // If the value for the last month is null
    // we need to use the month before
    const last = data[data.length - 1];
    const prev = data[data.length - 2];
    const hasValueForLastMonth = last.value || last.value === 0;

    return hasValueForLastMonth ? last : prev;
};

export const getPvEfficiency = (
    data: EfficiencyCheckModelV2[],
): HistoricalData['pvEfficiency'] => {
    if (!data || !Array.isArray(data) || !data.length) {
        return {
            value: null,
            timestamp: Moment().toISOString(),
        };
    }

    const source = getPvEfficiencySource(data);
    const value = source.value;
    const timestamp = source.from;

    return {
        value: typeof value === 'number' ? value * 100 : null,
        timestamp: timestamp as unknown as string,
    };
};

// Get this from the forecast when implemented in the BE
// And if enabled in the FE
export const getSunData = (date: Date) => {
    const sunrise: string = Moment(date)
        .set('hours', 8)
        .set('minutes', 55)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString();

    const sunset: string = Moment(date)
        .set('hours', 17)
        .set('minutes', 35)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString();

    return { sunrise, sunset };
};

export const getAggregatedData = async (
    date: Date,
    res: HistoricalResolution,
    dispatch: Dispatch,
    historicalData: HistoricalDataBranch,
    energyState: AggregatedDataEnergyState,
    userState: AggregatedDataUserState,
    wallboxInstalled: boolean,
    electricCarInstalled: boolean,
    type: GraphType,
): Promise<AggregatedDataAction> => {
    const { timezone } = userState;
    const {
        hasInverter,
        hasGasMeter,
        hasUkSmartMeter,
        solarCloudEndDate,
        solarCloudStartDate,
        hasElectricityMeter,
    } = energyState;

    const dates = getRequestDates(date, res, true);
    const { startingDate, resolutionEndDate, resolutionStartDate } = dates;

    const shouldGetPower = (
        [GraphTypes.OVERVIEW_POWER] as ReadonlyArray<GraphType>
    ).includes(type);

    // TODO: Consider removing the solar cloud exceptions when the BE handles the 500 on /energy
    // https://jira.eon.com/browse/HS-9627 && https://jira.eon.com/browse/HS-9625
    const shouldGetEnergy =
        type === GraphTypes.EMOBILITY ||
        type === GraphTypes.SOLAR_CLOUD ||
        type === GraphTypes.SOLAR_CLOUD_BALANCE
            ? false
            : !(
                  [GraphTypes.OVERVIEW_POWER] as ReadonlyArray<GraphType>
              ).includes(type);

    const solarCloudStatus = checkProductActivationForPeriod(
        {
            solarCloudEndDate,
            solarCloudStartDate,
        },
        resolutionStartDate,
        resolutionEndDate,
    );

    const shouldGetPvForecast = (
        [GraphTypes.INVERTER, 'all'] as ReadonlyArray<GraphType>
    ).includes(type);

    const historicalDataItem = historicalData[res].find(
        (item: any) =>
            item.data.type === type && item.startDate === startingDate,
    );

    dispatch(setAggregatedDataLoading(res, type));

    if (historicalDataItem) {
        const payload = historicalDataItem.data;
        const itemDate = Moment(historicalDataItem.timestamp);

        const expirationDate = isToday(date, res)
            ? itemDate.add(
                  AGGREGATION_THRESHOLD.AMOUNT,
                  AGGREGATION_THRESHOLD.UNIT,
              )
            : itemDate.endOf('day');

        const hasCachedPvForecast = shouldGetPvForecast
            ? payload.data?.forecast?.[res]?.data?.length > 0
            : true;

        const hasCachedPower = shouldGetPower
            ? payload.data?.inverterPower?.[res]?.data?.length > 0
            : true;

        // Force full re-render in the graphs
        await delay(800);

        if (
            hasCachedPower &&
            hasCachedPvForecast &&
            Moment().isBefore(expirationDate)
        ) {
            payload.type = type;

            dispatch({
                payload,
                type: AggregatedDataActionTypes.FETCH,
            });

            return Promise.resolve({
                payload,
                type: AggregatedDataActionTypes.FETCH,
            });
        }
    }

    const v2Data: AggregatedDataProperties = await getAggregatedDataV2(
        date,
        res,
        userState,
        !!hasGasMeter,
        shouldGetPower,
        shouldGetEnergy,
        solarCloudStatus !== SolarCloudStatus.INACTIVE,
    );

    // There can only be a wallbox OR an electric car, not both at the same time
    const emobilityData: HistoricalEmobilityData = wallboxInstalled
        ? await getHistoricalWallboxData(date, res, userState, dispatch)
        : electricCarInstalled
        ? await getHistoricalElectricCarData(date, res, userState, dispatch)
        : { prevDay: {} };

    const [prevForecastData, forecastData] = !shouldGetPvForecast
        ? emptyResponse
        : hasInverter
        ? await getPvForecast(date, res, userState)
        : emptyResponse;

    const { prevDay, ...currentV2Data } = v2Data;

    const { prevDay: prevEmobilityData, ...currentEmobilityData } =
        emobilityData;

    const normalizedForecastData = forecastData.map(({ values, ...rest }) => ({
        ...values,
        ...rest,
    }));

    const normalizedPrevForecastData = prevForecastData
        .map(({ values, ...rest }) => ({ ...values, ...rest }))
        .map((item) => ({
            ...item,
            timestamp: getModifiedDate(
                res,
                item.timestamp as unknown as string,
                Moment(date).date(),
                true,
            ),
        }));

    const currentV2EnergyForecast = extractAggregatedData(
        date,
        normalizedForecastData,
        res,
        'PVenergyOutTotal' as any,
        timezone,
        false,
        currentV2Data?.generation?.[res]?.data,
    );

    const currentV2PowerForecast = extractAggregatedData(
        date,
        normalizedForecastData,
        res,
        'PVpowerOutTotal' as any,
        timezone,
        false,
        currentV2Data?.generation?.[res]?.data,
    );

    const prevV2EnergyForecast = extractAggregatedData(
        date,
        normalizedPrevForecastData,
        res,
        'PVenergyOutTotal' as any,
        timezone,
        false,
        prevDay?.prevGeneration?.[res]?.data,
    );

    const prevV2PowerForecast = extractAggregatedData(
        date,
        normalizedPrevForecastData,
        res,
        'PVpowerOutTotal' as any,
        timezone,
        false,
        prevDay?.prevGeneration?.[res]?.data,
    );

    const data: AggregatedDataProperties = {
        forecast: currentV2EnergyForecast,
        nightTariff: {
            [res]: getSunData(date),
        },
        PVpowerOutTotal: currentV2PowerForecast,
        PVenergyOutTotal: currentV2EnergyForecast,
        solarCloudStatus: checkProductActivationForPeriod(
            {
                solarCloudEndDate,
                solarCloudStartDate,
            },
            resolutionStartDate,
            resolutionEndDate,
        ),

        ...currentV2Data,
        ...currentEmobilityData,
        prevDay: {
            prevForecast: prevV2EnergyForecast,
            prevPVpowerOutTotal: prevV2PowerForecast,
            prevPVenergyOutTotal: prevV2EnergyForecast,
            ...prevDay,
            ...prevEmobilityData,
        },
    };

    let lastReadingKey: keyof AggregatedDataProperties = 'generation';

    if (hasUkSmartMeter) {
        lastReadingKey =
            hasGasMeter && hasElectricityMeter
                ? 'fromGridElectricity'
                : hasGasMeter
                ? 'fromGridGas'
                : 'fromGridElectricity';
    }

    const lastReadingSource = v2Data[lastReadingKey];

    const payload = {
        res,
        type,
        lastReading: getLastReadingDate(
            lastReadingSource ? lastReadingSource[res].data : [],
        ),
        data,
    };

    const timestamp = new Date().getTime();
    const action = {
        payload,
        type: AggregatedDataActionTypes.FETCH,
    };

    dispatch(
        setHistoricalData(HistoricalDataActionTypes.SET_AGGREGATED_DATA, {
            type: HistoricalDataTypes.EC,
            data: payload,
            startDate: startingDate,
            timestamp,
            resolution: res,
        }),
    );

    dispatch(action);

    if (v2Data.error) {
        dispatch(setAggregatedDataError(res, type));
    }

    return action;
};

export const setHistoricalDataForMonth = (
    dispatch: Dispatch,
    data: any,
    isPromise: boolean = false,
): any => {
    const { donuts, energyFlow, pvEfficiency } = data;

    dispatch(
        setHistoricalData(
            HistoricalDataActionTypes.SET_AGGREGATED_DATA_FOR_DONUTS,
            donuts,
        ),
    );

    dispatch(
        setHistoricalData(
            HistoricalDataActionTypes.SET_AGGREGATED_DATA_FOR_ENERGY_FLOW,
            energyFlow,
        ),
    );

    dispatch(
        setHistoricalData(
            HistoricalDataActionTypes.SET_PV_EFFICIENCY,
            pvEfficiency,
        ),
    );

    if (energyFlow?.data?.today) {
        const { requestEndDate, requestStartDate, shouldDisable30Days } =
            energyFlow.data.today;

        dispatch({
            type: EnergyFlowActionTypes.SET_REQUEST_DATES,
            payload: {
                requestStartDate,
                requestEndDate,
                shouldDisable30Days,
            },
        });
    }

    const result = {
        donutsData: donuts.data,
        energyFlowData: energyFlow.data,
    };

    return isPromise ? Promise.resolve(result) : result;
};

const isDataStillValid = (timestamp: number): boolean => {
    const itemDate = Moment(timestamp);
    const expirationDate = itemDate.add(
        AGGREGATION_THRESHOLD.AMOUNT,
        AGGREGATION_THRESHOLD.UNIT,
    );

    return Moment().isBefore(expirationDate);
};

const formatAggregatedDataForMonth = (
    donutsData: DonutsDataModel,
    startDate: string,
    efficiencyData: any[],
): Record<string, any> => {
    const timestamp = new Date().getTime();

    return {
        donuts: {
            data: donutsData.donuts,
            type: HistoricalDataTypes.DONUTS,
            startDate,
            timestamp,
            shouldRender: true,
        },
        energyFlow: {
            data: donutsData.energyFlow,
            type: HistoricalDataTypes.EF,
            startDate,
            timestamp,
        },
        pvEfficiency: getPvEfficiency(efficiencyData),
    };
};

export const getAggregatedDataForMonth = async (
    date: Date,
    dispatch: Dispatch,
    historicalData: HistoricalData,
    energyState: AggregatedDataEnergyState,
    userState: AggregatedDataUserState,
): Promise<Record<string, any>> => {
    // prettier-ignore
    const { hasInverter, hasSmartMeter, hasUkSmartMeter, hasPvEfficiency } = energyState;

    const res = ExtendedHistoricalResolution.THIRTY_DAYS;
    const dates = getRequestDates(date, res);
    const smartMeterInstalled = !!hasSmartMeter;
    // prettier-ignore
    const hasHistoricalDataItem = historicalData.donuts.startDate === dates.startDate;

    if (
        hasHistoricalDataItem &&
        isDataStillValid(historicalData.donuts.timestamp)
    ) {
        return setHistoricalDataForMonth(dispatch, historicalData, true);
    }

    const efficiencyData = hasPvEfficiency ? await fetchPvEfficiency() : [];

    const emptyDonutsData: DonutsDataModel = {
        donuts: {
            day: null,
            week: null,
            month: null,
            year: null,
        },
        energyFlow: {
            today: null,
            yesterday: null,
            '30d': null,
        },
    };

    const donutsData = !hasUkSmartMeter
        ? await getDonutsData(
              hasInverter,
              smartMeterInstalled,
              userState,
              emptyDonutsData,
          )
        : emptyDonutsData;

    const formattedHistoricalDataForMonth = formatAggregatedDataForMonth(
        donutsData,
        dates.startDate,
        efficiencyData,
    );

    return setHistoricalDataForMonth(dispatch, formattedHistoricalDataForMonth);
};

export const getAllAggregatedData = async (
    dispatch: Dispatch,
    historicalData: HistoricalData,
    energyState: AggregatedDataEnergyState,
    userState: AggregatedDataUserState,
    wallboxInstalled: boolean,
    electricCarInstalled: boolean,
    date: Date = new Date(),
): Promise<void> => {
    const { hasInverter, hasUkSmartMeter, hasSmartMeter } = energyState;
    const res = HistoricalResolution.DAY1HOUR;
    const hasAnySmartMeter = !!hasUkSmartMeter || hasSmartMeter;

    if (!hasAnySmartMeter && !hasInverter) {
        return;
    }

    await getAggregatedData(
        hasAnySmartMeter ? Moment(date).subtract(1, 'day').toDate() : date,
        res,
        dispatch,
        historicalData.aggregatedData,
        energyState,
        userState,
        wallboxInstalled,
        electricCarInstalled,
        'all',
    ).then((action: AggregatedDataAction) => {
        dispatch({
            type: AggregatedDataActionTypes.FETCH,
            payload: {
                ...action.payload,
                type: 'all',
            },
        });

        if (hasAnySmartMeter && !hasUkSmartMeter) {
            getAggregatedData(
                date,
                res,
                dispatch,
                historicalData.aggregatedData,
                { ...energyState, gcp: false, hasGCP: false },
                userState,
                wallboxInstalled,
                electricCarInstalled,
                GraphTypes.INVERTER,
            ).then((a: AggregatedDataAction) => {
                dispatch({
                    type: AggregatedDataActionTypes.FETCH,
                    payload: {
                        ...a.payload,
                        type: GraphTypes.INVERTER,
                    },
                });
            });
        }
    });

    if (!hasInverter) {
        return;
    }

    await getAggregatedDataForMonth(
        date,
        dispatch,
        historicalData,
        energyState,
        userState,
    );
};

export const setAggregatedDataDate = (payload: Date) => ({
    type: AggregatedDataActionTypes.SET_DATE,
    payload,
});

export const setAggregatedDataRes = (payload: HistoricalResolution) => ({
    type: AggregatedDataActionTypes.SET_RESOLUTION,
    payload,
});

export const downloadAggregatedData = async (
    dates: Record<'startDate' | 'endDate', string>,
    res: Res,
    userState: AggregatedDataUserState,
): Promise<AggregatedDataProperties> =>
    getAggregatedDataV2(
        new Date(),
        res as HistoricalResolution,
        userState,
        false,
        false,
        true,
        false,
        dates,
    );

const getAggregatedDataFromEnergy = (
    date: Date,
    energy: EnergyModel[],
    res: HistoricalResolution,
    timezone: string,
) => {
    const energyKeys: (keyof EnergyHistoryV2ResponseModel)[] = [
        'generation',
        'consumptionFromPv',
        'pvToBattery',
        'toBattery',
        'fromBattery',
        'soc',
        'pvToGrid',
        'fromGrid',
        'consumption',
        'selfConsumption',
        'selfSufficiency',
        'consumptionFromBattery',
        'consumptionFromGrid',
        'pvGeneration',
        'electricCarCharging',
        'carCharging',
    ];

    const aggregatedEnergyData = energyKeys.reduce((acc, key) => {
        acc[key] = extractAggregatedData(date, energy, res, key, timezone);
        return acc;
    }, {} as Record<keyof EnergyHistoryV2ResponseModel, AggregatedDataModel>);

    return aggregatedEnergyData;
};

const getAggregatedDataFromSolarCloud = (
    date: Date,
    energy: SolarCloudModel[],
    res: HistoricalResolution,
    timezone: string,
) => {
    const solarCloudKeys: (keyof SolarCloudHistoryV2ResponseModel)[] = [
        'solarCloudBalanceDelta',
        'fromSolarCloud',
        'solarCloudEnabled',
        'toSolarCloud',
    ];

    const aggregatedSolarCloudData = solarCloudKeys.reduce((acc, key) => {
        acc[key] = extractAggregatedData(date, energy, res, key, timezone);
        return acc;
    }, {} as Record<keyof SolarCloudHistoryV2ResponseModel, AggregatedDataModel>);

    return aggregatedSolarCloudData;
};

const getAggregatedDataFromPower = (
    date: Date,
    energy: PowerModel[],
    res: HistoricalResolution,
    timezone: string,
) => {
    const powerKeys: (keyof PowerHistoryV2ResponseModel)[] = [
        'inverter',
        'battery',
        'grid',
        'consumption',
        'selfConsumption',
    ];

    const aggregatedPowerData = powerKeys.reduce((acc, key) => {
        acc[key] = extractAggregatedData(date, energy, res, key, timezone);
        return acc;
    }, {} as Record<keyof PowerHistoryV2ResponseModel, AggregatedDataModel>);

    return aggregatedPowerData;
};

const getAggregatedDataFromGas = (
    date: Date,
    energy: GasModel[],
    res: HistoricalResolution,
    timezone: string,
) => {
    const powerKeys: (keyof GasHistoryV2ResponseModel)[] = [
        'fromGrid',
        'fromGridCosts',
    ];

    const aggregatedGasData = powerKeys.reduce((acc, key) => {
        acc[key] = extractAggregatedData(date, energy, res, key, timezone);
        return acc;
    }, {} as Record<keyof GasHistoryV2ResponseModel, AggregatedDataModel>);

    return aggregatedGasData;
};

export const getAggregatedDataV2 = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
    shouldFetchGas: boolean,
    shouldFetchPower: boolean,
    shouldFetchEnergy: boolean,
    shouldFetchSolarCloud: boolean,
    dates?: Record<'startDate' | 'endDate', string>,
): Promise<AggregatedDataProperties> => {
    const config = createRequestConfiguration();
    const pvbAPI = new PvBatteryApi(config);
    const resolution = getResolution(res);
    const comparisonInterval = getComparisonInterval(res);
    // prettier-ignore
    const { startDate, endDate } = dates || getRequestDates(date, res, false);
    const { timezone, registrationDate } = userState;
    const args = {
        fromDate: startDate,
        toDate: endDate,
        interval: resolution,
        comparisonInterval,
    };

    const energy =
        shouldFetchEnergy && checkForScopes([Scope.ENERGYDEVICES_PVB_READ])
            ? pvbAPI.pvBatteryGetEnergyAggregatedV2(args)
            : Promise.resolve([]);

    const power =
        shouldFetchPower && checkForScopes([Scope.ENERGYDEVICES_PVB_READ])
            ? pvbAPI.pvBatteryGetPowerAggregatedV2(args)
            : Promise.resolve([]);

    const solarCloud =
        shouldFetchSolarCloud && checkForScopes([Scope.ENERGYDEVICES_PVB_READ])
            ? pvbAPI.pvBatteryGetSolarCloudBalanceHistoryV2(args)
            : Promise.resolve([]);

    const gas =
        shouldFetchGas && checkForScopes([Scope.ENERGYDEVICES_GAS_READ])
            ? pvbAPI.pvBatteryGetGasAggregatedV2(args)
            : Promise.resolve([]);

    return Promise.all([energy, power, solarCloud, gas])
        .then((responses) => {
            const data = responses.map((r) =>
                splitInTwo(
                    clearDataBeforeRegistrationDate(
                        [...r],
                        registrationDate,
                        res,
                    ),
                ).map((item) => mapToLocalTimeZone(item, res)),
            );

            const [currentEnergy, prevEnergy]: EnergyModel[][] = data[0];
            const [currentPower, prevPower]: PowerModel[][] = data[1];
            const [currentSolarCloud, prevSolarCloud]: SolarCloudModel[][] =
                data[2];
            const [currentGasData, prevGasData]: GasModel[][] = data[3];

            // aggregated current energy data
            const currAggrEnergy = getAggregatedDataFromEnergy(
                date,
                currentEnergy,
                res,
                timezone,
            );

            const currentFromGridElectricityCosts = extractAggregatedData(
                date,
                currentEnergy,
                res,
                'fromGridCosts',
                timezone,
                true,
            );

            // aggregated previous energy data
            const prevAggrEnergy = getAggregatedDataFromEnergy(
                date,
                prevEnergy,
                res,
                timezone,
            );

            const prevFromGridElectricityCosts = extractAggregatedData(
                date,
                prevEnergy,
                res,
                'fromGridCosts',
                timezone,
                true,
            );

            // aggregated current solar cloud data
            const currAggrSolarCloud = getAggregatedDataFromSolarCloud(
                date,
                currentSolarCloud,
                res,
                timezone,
            );

            const prevAggrSolarCloud = getAggregatedDataFromSolarCloud(
                date,
                prevSolarCloud,
                res,
                timezone,
            );

            const currAggrPower = getAggregatedDataFromPower(
                date,
                currentPower,
                res,
                timezone,
            );

            const prevAggrPower = getAggregatedDataFromPower(
                date,
                prevPower,
                res,
                timezone,
            );

            const currAggrGas = getAggregatedDataFromGas(
                date,
                currentGasData,
                res,
                timezone,
            );

            const prevAggrGas = getAggregatedDataFromGas(
                date,
                prevGasData,
                res,
                timezone,
            );

            // usually not necessary to save this in a const, but will make our lives easier once we support electric car & wallbox to find the places where we check for it
            const hasElectricCar = !!currAggrEnergy.electricCarCharging;

            return {
                PV2home: currAggrEnergy.generation,
                PV2homeToBattery: currAggrEnergy.pvToBattery,
                PV2homeToGrid: currAggrEnergy.pvToGrid,
                PV2homeToHome: currAggrEnergy.consumptionFromPv,
                balance: currAggrSolarCloud.solarCloudBalanceDelta,
                batteryEnergyBattery2Home: currAggrEnergy.fromBattery,
                batteryEnergyHome2Battery: currAggrEnergy.pvToBattery,
                batteryEnergyStateOfCharge: currAggrEnergy.soc,
                batteryPower: currAggrPower.battery,
                charging: currAggrEnergy.toBattery,
                discharging: currAggrEnergy.fromBattery,
                emobility: hasElectricCar
                    ? currAggrEnergy.electricCarCharging
                    : currAggrEnergy.carCharging,
                energyConsumption: currAggrEnergy.consumption,
                energyConsumptionFromBattery:
                    currAggrEnergy.consumptionFromBattery,
                energyConsumptionFromGrid: currAggrEnergy.consumptionFromGrid,
                energyConsumptionFromPV: currAggrEnergy.consumptionFromPv,
                fromGridElectricity: currAggrEnergy.fromGrid,
                fromGridElectricityCosts: currentFromGridElectricityCosts,
                energyGrid2home: currAggrEnergy.fromGrid,
                energyHome2grid: currAggrEnergy.pvToGrid,
                energySelfConsumption: currAggrEnergy.selfConsumption,
                exported: currAggrEnergy.pvToGrid,
                fromGridGas: currAggrGas.fromGrid,
                fromGridGasCosts: currAggrGas.fromGridCosts,
                fromSolarCloud: currAggrSolarCloud.fromSolarCloud,
                generation: currAggrEnergy.pvGeneration,
                gridPower: currAggrPower.grid,
                household: currAggrEnergy.consumption,
                imported: currAggrEnergy.fromGrid,
                inverterPower: currAggrPower.inverter,
                nightTariff: {
                    [res]: getSunData(date),
                },
                power: currAggrPower.inverter,
                powerConsumption: currAggrPower.consumption,
                powerSelfConsumption: currAggrPower.selfConsumption,
                pvbattery: currAggrEnergy.generation,
                saved: currAggrEnergy.toBattery,
                self: currAggrEnergy.selfConsumption,
                selfsufficiency: currAggrEnergy.selfSufficiency,
                selfSufficiency: currAggrEnergy.selfSufficiency,
                solarCloudBalance: currAggrSolarCloud.solarCloudBalanceDelta,
                solarCloudBalanceDelta:
                    currAggrSolarCloud.solarCloudBalanceDelta,
                solarCloudEnabled: currAggrSolarCloud.solarCloudEnabled,
                state: currAggrEnergy.soc,
                toSolarCloud: currAggrSolarCloud.toSolarCloud,
                prevDay: {
                    prevPV2home: prevAggrEnergy.generation,
                    prevPV2homeToBattery: prevAggrEnergy.pvToBattery,
                    prevPV2homeToGrid: prevAggrEnergy.pvToGrid,
                    prevPV2homeToHome: prevAggrEnergy.consumptionFromPv,
                    prevBalance: currAggrSolarCloud.solarCloudBalanceDelta,
                    prevBatteryEnergyBattery2Home: prevAggrEnergy.fromBattery,
                    prevBatteryEnergyHome2Battery: prevAggrEnergy.pvToBattery,
                    prevBatteryEnergyStateOfCharge: prevAggrEnergy.soc,
                    prevBatteryPower: prevAggrPower.battery,
                    prevCharging: prevAggrEnergy.toBattery,
                    prevDischarging: prevAggrEnergy.fromBattery,
                    prevEmobility: hasElectricCar
                        ? prevAggrEnergy.electricCarCharging
                        : prevAggrEnergy.carCharging,
                    prevEnergyConsumption: prevAggrEnergy.consumption,
                    prevEnergyConsumptionFromBattery:
                        prevAggrEnergy.consumptionFromBattery,
                    prevEnergyConsumptionFromGrid:
                        prevAggrEnergy.consumptionFromGrid,
                    prevEnergyConsumptionFromPV:
                        prevAggrEnergy.consumptionFromPv,
                    prevFromGridElectricity: prevAggrEnergy.fromGrid,
                    prevFromGridElectricityCosts: prevFromGridElectricityCosts,
                    prevEnergyGrid2home: prevAggrEnergy.fromGrid,
                    prevEnergyHome2grid: prevAggrEnergy.pvToGrid,
                    prevEnergySelfConsumption: prevAggrEnergy.selfConsumption,
                    prevExported: prevAggrEnergy.pvToGrid,
                    prevFromSolarCloud: prevAggrSolarCloud.fromSolarCloud,
                    prevFromGridGas: prevAggrGas.fromGrid,
                    prevFromGridGasCosts: prevAggrGas.fromGridCosts,
                    prevGeneration: prevAggrEnergy.pvGeneration,
                    prevGridPower: prevAggrPower.grid,
                    prevHousehold: prevAggrEnergy.consumption,
                    prevImported: prevAggrEnergy.fromGrid,
                    prevInverterPower: prevAggrPower.inverter,
                    prevPower: prevAggrPower.inverter,
                    prevPowerConsumption: prevAggrPower.consumption,
                    prevPowerSelfConsumption: prevAggrPower.selfConsumption,
                    prevPvbattery: prevAggrEnergy.generation,
                    prevSaved: prevAggrEnergy.toBattery,
                    prevSelf: prevAggrEnergy.selfConsumption,
                    prevSelfsufficiency: prevAggrEnergy.selfSufficiency,
                    prevSelfSufficiency: prevAggrEnergy.selfSufficiency,
                    prevSolarCloudBalance:
                        currAggrSolarCloud.solarCloudBalanceDelta,
                    prevSolarCloudBalanceDelta:
                        currAggrSolarCloud.solarCloudBalanceDelta,
                    prevSolarCloudEnabled: prevAggrSolarCloud.solarCloudEnabled,
                    prevState: prevAggrEnergy.soc,
                    prevToSolarCloud: prevAggrSolarCloud.toSolarCloud,
                },
            };
        })
        .catch(async (error: ResponseError) => {
            await handleError(error, 'Error when getting aggregated data V2:');

            return { error };
        });
};

export const getSingleDonutData = (
    response: DonutModel[],
    timeline: PvBatteryDonutHistoryV2ResponseModelTimelineEnum,
): Nullable<HistoricalDonutDataModel> => {
    const item = response.find(
        (item: DonutModel) => item.timeline === timeline,
    );

    if (!item) {
        return null;
    }

    let { generation, consumption } = item;

    if (!generation) {
        generation = 0;
    }

    if (!consumption) {
        consumption = 0;
    }

    return {
        length: 1,
        timestamp: '',
        shouldDisable: false,
        values: {
            generation: {
                total: generation,
                toHome: item.toHome,
                inBattery: item.toBattery,
                toGrid: item.toGrid,
                fill: generation > consumption ? 0 : consumption - generation,
            },
            consumption: {
                total: consumption,
                fromPv: item.fromPv,
                fromBattery: item.fromBattery,
                fromGrid: item.fromGrid,
                fill: consumption > generation ? 0 : generation - consumption,
            },
        },
    };
};

export const getEnergyFlowRequestDates = (
    timezone: string,
    is30Days: boolean,
    smartMeterInstalled: boolean,
): [string, string] => {
    const subtractFromEnd = smartMeterInstalled ? 1 : 0;

    let subtractFromStart = smartMeterInstalled ? 1 : 0;

    if (is30Days) {
        subtractFromStart = smartMeterInstalled ? 31 : 30;
    }

    return [
        Moment()
            .startOf('day')
            .subtract(subtractFromStart, 'days')
            .format(AGGREGATION_TIME_FORMAT),
        Moment()
            .tz(timezone)
            .subtract(subtractFromEnd, 'days')
            .format(AGGREGATION_TIME_FORMAT),
    ];
};

export const getSingleEnergyFlowData = (
    response: DonutModel[],
    timeline: PvBatteryDonutHistoryV2ResponseModelTimelineEnum,
    registrationDate: string,
    requestStartDate: string,
    requestEndDate: string,
): Nullable<HistoricalDataEnergyFlowBranch> => {
    const item = response.find(
        (item: DonutModel) => item.timeline === timeline,
    );

    if (!item) {
        return null;
    }

    // usually not necessary to save this in a const, but will make our lives easier once we support electric car & wallbox to find the places where we check for it
    const hasElectricCar = !!item.electricCarCharging;

    return {
        gcpState: {
            loading: false,
            home2grid: item.toGrid,
            grid2home: item.fromGrid,
        },
        emobilityState: {
            wattHour: hasElectricCar
                ? item.electricCarCharging
                : item.carCharging,
        },
        energyState: {
            error: false,
            loading: false,
            battery2home: item.fromBattery,
            home2battery: item.toBattery,
            historicalPvGeneration: item.generation,
            historicalConsumption: item.consumption,
            historicalSelfSufficiency: Math.round(item.selfSufficiency || 0),
        },
        requestStartDate,
        requestEndDate,
        shouldDisable30Days: Moment(registrationDate).isAfter(
            Moment().subtract(30, 'days'),
        ),
    };
};

const sum = (data: GCPModel[], key: string): number =>
    data.reduce(
        (result: number, item: GCPModel) =>
            result + (item.values?.[key as keyof GCPModel['values']] || 0),
        0,
    );

export const getDonutsData = async (
    hasInverter: boolean,
    hasSmartMeter: boolean,
    userState: AggregatedDataUserState,
    fallback: DonutsDataModel,
): Promise<DonutsDataModel> => {
    const config = createRequestConfiguration();
    const { timezone, registrationDate } = userState;

    const fromDate = !hasSmartMeter
        ? undefined
        : Moment()
              .endOf('day')
              .subtract(31, 'days')
              .format(AGGREGATION_TIME_FORMAT);

    const toDate = !hasSmartMeter
        ? undefined
        : Moment()
              .endOf('day')
              .subtract(1, 'day')
              .format(AGGREGATION_TIME_FORMAT);

    const promise = hasInverter
        ? new PvBatteryApi(config).pvBatteryGetHistoryDonutAggregatedV2({
              toDate,
          })
        : new GCPApi(config)
              .gcpGetHistoryAggregated({
                  fromDate: fromDate || '',
                  toDate: toDate || '',
              })
              .then((response: GCPModel[]) => {
                  const day = response[response.length - 1];
                  const week = response.slice(-7);
                  const month = response.slice(-30);

                  return [
                      {
                          toGrid: day.values?.energyHome2grid,
                          fromGrid: day.values?.energyGrid2home,
                          timeline: Currently,
                      },
                      {
                          toGrid: sum(week, 'energyHome2grid'),
                          fromGrid: sum(week, 'energyGrid2home'),
                          timeline: _7days,
                      },
                      {
                          toGrid: sum(month, 'energyHome2grid'),
                          fromGrid: sum(month, 'energyGrid2home'),
                          timeline: _30days,
                      },
                  ];
              });

    return await promise
        .then((response: DonutModel[]) => {
            const energyFlowToday = getSingleEnergyFlowData(
                response,
                Currently,
                registrationDate,
                ...getEnergyFlowRequestDates(timezone, false, hasSmartMeter),
            );

            return {
                donuts: {
                    day: getSingleDonutData(response, Currently),
                    week: getSingleDonutData(response, _7days),
                    month: getSingleDonutData(response, _30days),
                    year: getSingleDonutData(response, _365days),
                },
                energyFlow: {
                    today: energyFlowToday,
                    yesterday: energyFlowToday,
                    '30d': getSingleEnergyFlowData(
                        response,
                        _30days,
                        registrationDate,
                        ...getEnergyFlowRequestDates(
                            timezone,
                            true,
                            hasSmartMeter,
                        ),
                    ),
                },
            };
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting aggregated donuts data:');

            return fallback;
        });
};
