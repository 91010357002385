import { TFunction } from 'i18next';
import {
    MeterSurveyRequestModelProductsEnum,
    MeterSurveyRequestModelHouseTypeEnum,
    MeterSurveyRequestModelHomeHeatingEnum,
    MeterSurveyRequestModelWaterHeatingEnum,
    MeterSurveyRequestModelYourPriorityEnum,
    MeterSurveyRequestModelHomeOwnershipEnum,
    MeterSurveyRequestModelHomeInsulationEnum,
} from '@swagger-http';

export interface FormOptions {
    text: string;
    value:
        | MeterSurveyRequestModelProductsEnum
        | MeterSurveyRequestModelHouseTypeEnum
        | MeterSurveyRequestModelHomeHeatingEnum
        | MeterSurveyRequestModelWaterHeatingEnum
        | MeterSurveyRequestModelYourPriorityEnum
        | MeterSurveyRequestModelHomeOwnershipEnum
        | MeterSurveyRequestModelHomeInsulationEnum
        | '1'
        | '0';
    disabled?: boolean;
}

export const getHomeType = (t: TFunction): FormOptions[] => [
    {
        text: t<string>('Bungalow'),
        value: MeterSurveyRequestModelHouseTypeEnum.Bungalow,
    },
    {
        text: t<string>('Detached house'),
        value: MeterSurveyRequestModelHouseTypeEnum.DetachedHouse,
    },
    {
        text: t<string>('Flat'),
        value: MeterSurveyRequestModelHouseTypeEnum.Flat,
    },
    {
        text: t<string>('Terraced house'),
        value: MeterSurveyRequestModelHouseTypeEnum.TerracedHouse,
    },
    {
        text: t<string>('Semi-detached house'),
        value: MeterSurveyRequestModelHouseTypeEnum.SemiDetachedHouse,
    },
];

export const getHomeOwnership = (t: TFunction): FormOptions[] => [
    {
        text: t<string>('Rent'),
        value: MeterSurveyRequestModelHomeOwnershipEnum.Rent,
    },
    {
        text: t<string>('Own'),
        value: MeterSurveyRequestModelHomeOwnershipEnum.Own,
    },
];

export const getProducts = (t: TFunction): FormOptions[] => [
    {
        value: MeterSurveyRequestModelProductsEnum.Solar,
        disabled: false,
        text: t<string>('Solar'),
    },
    {
        value: MeterSurveyRequestModelProductsEnum.SolarBattery,
        disabled: false,
        text: t<string>('Solar & Battery'),
    },
    {
        value: MeterSurveyRequestModelProductsEnum.ElectricVehicle,
        disabled: false,
        text: t<string>('Electric vehicle'),
    },
    {
        value: MeterSurveyRequestModelProductsEnum.EvChargePoint,
        disabled: false,
        text: t<string>('EV charge point'),
    },
    {
        value: MeterSurveyRequestModelProductsEnum.AirSourceHeatPump,
        disabled: false,
        text: t<string>('Air source heat pump'),
    },
    {
        value: MeterSurveyRequestModelProductsEnum.GroundSourceHeatPump,
        disabled: false,
        text: t<string>('Ground source heat pump'),
    },
];

export const getHeating = (t: TFunction): FormOptions[] => [
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.GasBoiler,
        text: t<string>('Gas boiler'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.ElectricStorageHeater,
        text: t<string>('Electric storage heater'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.OilBoiler,
        text: t<string>('Oil boiler'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.LpgBoiler,
        text: t<string>('LPG boiler'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.Coal,
        text: t<string>('Coal'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.AirSourceHeatPump,
        text: t<string>('Air source heat pump'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.GroundSourceHeatPump,
        text: t<string>('Ground source heat pump'),
    },
    {
        value: MeterSurveyRequestModelHomeHeatingEnum.Others,
        text: t<string>('Others'),
    },
];

export const getWaterHeating = (t: TFunction): FormOptions[] => [
    {
        value: MeterSurveyRequestModelWaterHeatingEnum.Gas,
        text: t<string>('Gas'),
    },
    {
        value: MeterSurveyRequestModelWaterHeatingEnum.Electricity,
        text: t<string>('Electricity'),
    },
    {
        value: MeterSurveyRequestModelWaterHeatingEnum.Oil,
        text: t<string>('Oil'),
    },
];

export const getHomeInsulation = (t: TFunction): FormOptions[] => [
    {
        value: MeterSurveyRequestModelHomeInsulationEnum.LoftInsulation,
        text: t<string>('Loft insulation'),
    },
    {
        value: MeterSurveyRequestModelHomeInsulationEnum.CavityWall,
        text: t<string>('Cavity wall'),
    },
    {
        value: MeterSurveyRequestModelHomeInsulationEnum.SolidWall,
        text: t<string>('Solid wall'),
    },
    {
        value: MeterSurveyRequestModelHomeInsulationEnum.DoubleGlazedWindows,
        text: t<string>('Double glazed windows'),
    },
    {
        value: MeterSurveyRequestModelHomeInsulationEnum.Nothing,
        text: t<string>('None of the above'),
    },
];

export const getYourPriority = (t: TFunction): FormOptions[] => [
    {
        value: MeterSurveyRequestModelYourPriorityEnum.SavingMoneyAndEnergy,
        text: t<string>('Saving money and energy'),
    },
    {
        value: MeterSurveyRequestModelYourPriorityEnum.ReduceCarbon,
        text: t<string>('Being green and reduce carbon'),
    },
    {
        value: MeterSurveyRequestModelYourPriorityEnum.SelfSufficient,
        text: t<string>('Becoming more self-sufficient'),
    },
    {
        value: MeterSurveyRequestModelYourPriorityEnum.ControlAndConvenience,
        text: t<string>('Have better control and convenience'),
    },
];

export const getBooleanOptions = (t: TFunction): FormOptions[] => [
    {
        value: '1',
        text: t<string>('Yes'),
    },
    {
        value: '0',
        text: t<string>('No'),
    },
];
