import { EnergyFlowActionTypes } from '@store/enums';
import { EnergyFlowState, EnergyFlowAction } from '@store/types';
import { initialState as initialEnergyState } from '@store/branches/energy/reducer';

export const initialState: EnergyFlowState = {
    emobilityState: {
        loading: true,
        error: false,
    },
    energyState: initialEnergyState,
    gcpState: {
        loading: true,
    },
    smartMeterState: {
        data: null,
        error: false,
        loading: true,
        timestamp: '',
    },
    requestStartDate: '',
    requestEndDate: '',
    shouldDisable30Days: true,
};

export default function reducer(
    state: EnergyFlowState = initialState,
    action: EnergyFlowAction,
): EnergyFlowState {
    switch (action.type) {
        case EnergyFlowActionTypes.PVBATTERY_STATUS:
            return {
                ...state,
                energyState: {
                    ...state.energyState,
                    ...action.payload,
                },
            };
        case EnergyFlowActionTypes.EMOBILITY_INSTALLED:
        case EnergyFlowActionTypes.EMOBILITY_ERROR:
            return {
                ...state,
                emobilityState: {
                    ...state.emobilityState,
                    ...action.payload.emobilityState,
                },
            };
        case EnergyFlowActionTypes.GCP_STATUS:
            return {
                ...state,
                gcpState: {
                    loading: false,
                    ...action.payload,
                },
            };
        case EnergyFlowActionTypes.EMOBILITY_STATUS:
            return {
                ...state,
                emobilityState: {
                    ...state.emobilityState,
                    ...action.payload,
                },
            };
        case EnergyFlowActionTypes.SET_ERROR_PVB_GRID:
            return {
                ...state,
                energyState: {
                    ...state.energyState,
                    error: true,
                    loading: false,
                },
                gcpState: {
                    ...state.gcpState,
                    loading: false,
                },
            };

        case EnergyFlowActionTypes.SET_REQUEST_DATES:
            return {
                ...state,
                ...action.payload,
            };
        case EnergyFlowActionTypes.SET_SMART_METER_STATE:
            return {
                ...state,
                smartMeterState: {
                    ...state.smartMeterState,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}
