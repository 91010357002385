import { Step } from 'react-joyride';
import { TFunction } from 'i18next';

import { isMobileOnly } from 'react-device-detect';

import { Routes } from '@tools/enums';
import { InverterProviders } from '@store/enums';
import { EnergyFlowScenarioState } from '@store/types';

export const decorateSteps = (steps: Step[]) =>
    steps.map((step: Step) => ({
        ...step,
        disableBeacon: true,
    }));

export const getTourSteps = (
    t: TFunction,
    route: Routes,
    provider: InverterProviders | '',
    isLoggedIn: boolean,
    energyState: EnergyFlowScenarioState,
): Step[] => {
    const graphSteps: Step[] = [
        {
            target: '.c-graph-resolutions__content',
            content: t<string>(
                'Choose the resolution in which you want your data to be displayed.',
            ),
        },
        {
            target: '.c-day-picker',
            content: t<string>(
                'Click on the date picker to choose a specific date.',
            ),
        },
        {
            target: '.c-history-graph__prev',
            content: t<string>(
                'Clicking this arrow will show you the previous period.',
            ),
        },
        ...(isMobileOnly
            ? []
            : [
                  {
                      target: '.c-graph-tooltip',
                      content: t<string>(
                          'Drag this slider to move it to a specific time. Click on the red arrow to toggle the details.',
                      ),
                  },
              ]),
        {
            target: '.c-graph-toggles',
            content: t<string>(
                'Click on the checkboxes to toggle specific sets of data.',
            ),
        },
    ];

    if (route.includes(Routes.ENERGY)) {
        return decorateSteps([
            {
                target: '.c-page__nav',
                content: t<string>(
                    'Click on the sub menu items to see your energy data as an overview or with a different focus.',
                ),
            },
            ...graphSteps,
            // prettier-ignore
            ...(route !== Routes.ENERGY_GENERATION && route !== Routes.ENERGY_CONSUMPTION
                ? [
                      {
                          target: '.c-graphs-switch',
                          content: t<string>(
                              'There is a "Power view" for the day resolution which you can access by toggling this switch button.',
                          ),
                      },
                  ]
                : []),
            ...(isMobileOnly
                ? []
                : [
                      {
                          target: '.c-historical-data-download button',
                          content: t<string>(
                              'Click here to download your energy data in CSV format.',
                          ),
                      },
                  ]),
        ]);
    }

    if (route.includes(Routes.MORE)) {
        return isLoggedIn
            ? decorateSteps([
                  {
                      target: '.c-page__nav',
                      content: t<string>(
                          'Click on the sub menu items to see more information about your account and {{ APPNAME }} in general.',
                      ),
                  },
              ])
            : [];
    }

    const routesSteps: Partial<Record<Routes, Step[]>> = {
        [Routes.AUTH]: [],
        [Routes.BASE]: [
            {
                target: isMobileOnly
                    ? '.c-header-mobile__center a'
                    : '.c-nav__logo',
                content: t<string>(
                    'Clicking on the logo will always take you to the home page.',
                ),
            },
            {
                target: isMobileOnly ? '.c-nav' : '.c-nav ul',
                content: t<string>(
                    'This is your application menu which allows for quick access to the main pages.',
                ),
            },
            {
                target: isMobileOnly
                    ? '.c-energy-flow-mobile__container'
                    : '.c-energy-flow',
                content: t<string>(
                    'The energy flow shows you how much energy your home consumes/produces and from which source it comes. This way you can quickly see how self-sufficient your home is.',
                ),
            },
            ...(!!provider
                ? [
                      {
                          target: '.c-energy-flow-switch',
                          content: energyState.hasSmartMeter
                              ? t<string>(
                                    'Here you can see your energy data in different periods - now, yesterday or over the past 30 days.',
                                )
                              : t<string>(
                                    'Here you can see your energy data in different periods - now, today or over the past 30 days.',
                                ),
                      },
                  ]
                : []),
        ],
        [Routes.ACTION_REQUIRED]: [],
        [Routes.CONNECT_HEATING_COOLING]: [],
        [Routes.CONNECT_WALLBOX]: [],
        [Routes.CONNECT_ELECTRIC_CAR]: [],
        [Routes.CONNECT_ELECTRIC_CAR_SUCCESS]: [],
        [Routes.CONNECT_SMART_LIGHTS]: [],
        [Routes.CONNECT_SMART_METER]: [],
        [Routes.CONNECT_SMART_PLUGS]: [],
        [Routes.CONNECT_SUCCESS]: [],
        [Routes.DEVICES]: [],
        [Routes.EMOBILITY]: graphSteps,
        [Routes.EMOBILITY_CHARGING_HISTORY]: [],
        [Routes.GOODBYE]: [],
        [Routes.INSIGHTS]: [],
        [Routes.INSIGHTS_PV]: [],
        [Routes.INSIGHTS_HEATING]: [],
        [Routes.INSIGHTS_EMOBILITY]: [],
        [Routes.INSIGHTS_SMART_METER]: [],
        [Routes.MOBILE_REGISTER]: [],
        [Routes.NOT_FOUND]: [],
        [Routes.OAUTH]: [],
        [Routes.REGISTER]: [],
        [Routes.SALES]: [],
        [Routes.SMART_METER]: [],
        [Routes.STEPUP_REGISTRATION]: [],
        [Routes.SURVEY]: [],
    };

    return decorateSteps(routesSteps[route] || []);
};
