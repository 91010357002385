import { Dispatch } from 'redux';

import { Region, saveRegion, UnknownFunction } from '@eon-home/react-library';

import {
    AuthenticationApi,
    GVSApi,
    GvsUserInfoResponseModel,
    IsGridXUserResponseModel,
    MeterSurveyRequestModel,
    ResponseError,
    SiteModel,
    SitesApi,
    SpotPriceRegionModel,
    SurveysApi,
    TariffApi,
    UserApi,
    UserDataDownloadInfoModel,
    UserDataDownloadUriModel,
    UserEncryptedReferenceModel,
} from '@swagger-http';

import {
    arrayFields,
    booleanFields,
} from '@containers/survey/home-profile/fields';
import { OMIT_VALUE } from '@tools/constants';
import { DownloadFormat, Scope } from '@tools/enums';
import {
    checkForScopes,
    createRequestConfiguration,
    getDefaultRegion,
    handleError,
    Moment,
} from '@tools/utils';
import { setSuccessToast } from '../settings/actions';
import { ExtendedUserModel } from '../settings/types';
import { SurveyType, UserActionTypes } from './enums';
import { UserAction } from './types';

export const getUserData =
    () =>
    (dispatch: Dispatch<any>): Promise<UserAction | void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userMe()
            .then((response: ExtendedUserModel) => {
                const {
                    name,
                    email,
                    source,
                    country,
                    has_sales,
                    tenant_id,
                    created_on,
                    customer_id,
                    should_get_gvs_info,
                } = response;
                const region = country || getDefaultRegion();

                saveRegion(region as unknown as Region);

                return dispatch({
                    type: UserActionTypes.SET_USER_DATA,
                    payload: {
                        name,
                        email,
                        source,
                        hasSales: has_sales,
                        tenantId: tenant_id,
                        // prettier-ignore
                        joinDate: Moment(Number(created_on) * 1000).toISOString(),
                        customerId: customer_id,
                        shouldGetGvsInfo: should_get_gvs_info,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when getting user data:');
            });
    };

export const changeEmail =
    (email: string) =>
    (dispatch: Dispatch): Promise<boolean | ResponseError> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve(false);
        }

        return new AuthenticationApi(createRequestConfiguration())
            .authenticationChangeEmail({
                accountChangeEmailModel: {
                    email,
                },
            })
            .then(() => {
                dispatch({
                    type: UserActionTypes.SET_EMAIL,
                    payload: {
                        email,
                    },
                });

                return true;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when changing email:');

                return e;
            });
    };

export const getSites = () => (dispatch: Dispatch<any>) => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve();
    }

    return new SitesApi(createRequestConfiguration())
        .sitesGetSites()
        .then((response: SiteModel[]) =>
            dispatch({
                type: UserActionTypes.GET_SITES,
                payload: response[0],
            }),
        )
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting the timezone:');
        });
};

const parseSurveyData = (
    data: MeterSurveyRequestModel,
    toBoolean: boolean,
): MeterSurveyRequestModel =>
    Object.keys(data).reduce<Record<string, unknown>>(
        (acc, key: keyof MeterSurveyRequestModel & typeof OMIT_VALUE) => {
            if (data[key] === null || data[key] === OMIT_VALUE) {
                return acc;
            }

            if (arrayFields.includes(key) && !data[key]) {
                return acc;
            }

            acc[key] = toBoolean
                ? booleanFields.includes(key)
                    ? data[key] === '1'
                        ? true
                        : false
                    : data[key]
                : booleanFields.includes(key)
                ? data[key] === true
                    ? '1'
                    : '0'
                : data[key];

            return acc;
        },
        {},
    );

export const getSurvey = (survey: SurveyType) => (dispatch: Dispatch<any>) => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve();
    }

    return new SurveysApi(createRequestConfiguration())
        .surveysGetSurvey({
            type: survey,
        })
        .then((payload: MeterSurveyRequestModel) => {
            dispatch({
                type: UserActionTypes.SET_HOME_PROFILE_SURVEY,
                payload: {
                    data: parseSurveyData(payload, false),
                    error: undefined,
                },
            });
        })
        .catch(async (e: ResponseError) => {
            let text = `Error when getting the ${survey} survey data.`;

            try {
                text = await e.response.clone().text();
            } catch (err) {}

            dispatch({
                type: UserActionTypes.SET_HOME_PROFILE_SURVEY,
                payload: {
                    data: undefined,
                    error: {
                        code: e.response.status,
                        text,
                    },
                },
            });

            await handleError(e, text);
        });
};

export const storeSurvey =
    (survey: SurveyType, data: MeterSurveyRequestModel) =>
    (dispatch: Dispatch<any>) => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new SurveysApi(createRequestConfiguration())
            .surveysStoreSurvey({
                type: survey,
                meterSurveyRequestModel: data,
            })
            .then(() => {
                dispatch({
                    type: UserActionTypes.SET_HOME_PROFILE_SURVEY,
                    payload: {
                        data,
                        error: undefined,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error when storing the ${survey} survey data:`,
                );
            });
    };

export const getEncryptedReference = () => (dispatch: Dispatch<any>) => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve();
    }

    return new UserApi(createRequestConfiguration())
        .userEncryptedReference()
        .then((response: UserEncryptedReferenceModel) => {
            dispatch({
                type: UserActionTypes.SET_ENCRYPTED_REFERENCE,
                payload: response.id,
            });
        })
        .catch(async (e: ResponseError) => {
            return await handleError(
                e,
                `Error getting the encrypted reference:`,
            );
        });
};

export const completeCMSSurvey =
    (completedSurveys: string[]) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'completedSurveys',
                userPreferenceAddModel: {
                    value: [{ completedSurveys }],
                },
            })
            .then(() => {
                dispatch({
                    type: UserActionTypes.SET_COMPLETED_CMS_SURVEYS,
                    payload: completedSurveys,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when setting referral program banner to seen:',
                );
            });
    };

// Privacy Data
export const setPrivacyDataError = (): UserAction => ({
    type: UserActionTypes.PRIVACY_DATA_ERROR,
    payload: {
        error: true,
        loading: false,
    },
});

export const setPrivacyDataLoading = (value: boolean): UserAction => ({
    type: UserActionTypes.PRIVACY_DATA_LOADING,
    payload: {
        error: false,
        loading: value,
    },
});

export const sendPrivacyDataRequest =
    () =>
    (dispatch: Dispatch<any>): Promise<UserAction | void> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        dispatch(setPrivacyDataLoading(true));

        return new UserApi(createRequestConfiguration())
            .userRequestData()
            .then(() =>
                dispatch({
                    type: UserActionTypes.GET_PRIVACY_DATA,
                    payload: { isAlreadySent: true, loading: false },
                }),
            )
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when requesting privacy data:');

                return dispatch(setPrivacyDataError());
            });
    };

export const deleteAccountRequest =
    (requestDelete: boolean) =>
    (dispatch: Dispatch<any>): UserAction => {
        dispatch(setPrivacyDataLoading(true));

        return dispatch({
            type: UserActionTypes.DELETE_ACCOUNT,
            payload: { requestDelete },
        });
    };

export const getPrivacyData =
    () =>
    (dispatch: Dispatch<any>): Promise<UserAction | void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        dispatch(setPrivacyDataLoading(true));

        return new UserApi(createRequestConfiguration())
            .userGetData()
            .then((response: UserDataDownloadInfoModel) => {
                let requestDate;
                let expirationDate;
                let isDownloadReady: boolean = false;
                let isAlreadySent: boolean = false;

                if (response.isProcessed) {
                    isDownloadReady = true;
                }

                if (response.creationDate) {
                    requestDate = Moment(response.creationDate);
                }

                if (response.expirationDate) {
                    expirationDate = Moment(response.expirationDate);
                }

                if (Moment().isBetween(requestDate, expirationDate)) {
                    isAlreadySent = true;
                }

                if (Moment() > Moment(expirationDate)) {
                    isAlreadySent = false;
                    isDownloadReady = false;
                }

                const requestDelete = false;
                const loading = false;

                return dispatch({
                    type: UserActionTypes.GET_PRIVACY_DATA,
                    payload: {
                        isAlreadySent,
                        isDownloadReady,
                        requestDate,
                        expirationDate,
                        requestDelete,
                        loading,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when getting privacy data:');

                if (e.response.status !== 404) {
                    dispatch(setPrivacyDataError());
                }

                dispatch(setPrivacyDataLoading(false));
            });
    };

export const deleteAccount =
    (logout: UnknownFunction) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        dispatch(setPrivacyDataLoading(true));

        return new UserApi(createRequestConfiguration())
            .userDeleteUser()
            .then(() => {
                dispatch(setPrivacyDataLoading(false));
                dispatch(deleteAccountRequest(false));
                logout();
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when deleting user account:');

                return dispatch(setPrivacyDataError());
            });
    };

export const getPrivacyDataDownloadLink = (
    fileFormat: DownloadFormat,
): Promise<string> => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve('');
    }

    return new UserApi(createRequestConfiguration())
        .userGetDownloadUri({
            fileFormat,
        })
        .then((response: UserDataDownloadUriModel) => {
            const { downloadLink } = response;
            return downloadLink
                ? Promise.resolve(downloadLink)
                : Promise.reject('Download link missing or corrupted');
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when getting download link:');

            return Promise.reject('Download link missing or corrupted');
        });
};

export const getBillingRegions =
    () =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.BILLING_REGION_READ])) {
            return;
        }

        dispatch({
            type: UserActionTypes.SET_BILLING_REGIONS_DATA,
            payload: {
                data: null,
                error: false,
                loading: true,
            },
        });

        try {
            const data = await new TariffApi(
                createRequestConfiguration(),
            ).tariffGetSpotPriceRegions1();

            dispatch({
                type: UserActionTypes.SET_BILLING_REGIONS_DATA,
                payload: {
                    data,
                    error: false,
                    loading: false,
                },
            });
        } catch ({ response }) {
            dispatch({
                type: UserActionTypes.SET_BILLING_REGIONS_DATA,
                payload: {
                    data: null,
                    error: true,
                    loading: false,
                },
            });

            await handleError(response, 'Unable to get billing regions:');
        }
    };

export const getBillingRegion =
    () =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.BILLING_REGION_READ])) {
            return;
        }

        dispatch({
            type: UserActionTypes.SET_BILLING_REGION_DATA,
            payload: {
                data: null,
                error: false,
                loading: true,
            },
        });

        try {
            const data = await new TariffApi(
                createRequestConfiguration(),
            ).tariffGetSpotPriceRegion();

            dispatch({
                type: UserActionTypes.SET_BILLING_REGION_DATA,
                payload: {
                    data,
                    error: false,
                    loading: false,
                },
            });
        } catch ({ response }) {
            dispatch({
                type: UserActionTypes.SET_BILLING_REGION_DATA,
                payload: {
                    data: null,
                    error: response.status !== 404,
                    loading: false,
                },
            });

            await handleError(response, 'Unable to get billing region:');
        }
    };

export const setBillingRegion =
    (spotPriceRegionModel: SpotPriceRegionModel, onSuccess?: () => void) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.BILLING_REGION_WRITE])) {
            return;
        }

        dispatch({
            type: UserActionTypes.SET_BILLING_REGION_DATA,
            payload: {
                error: false,
                loading: true,
            },
        });

        try {
            await new TariffApi(
                createRequestConfiguration(),
            ).tariffPutSpotPriceRegion({
                spotPriceRegionModel,
            });

            dispatch({
                type: UserActionTypes.SET_BILLING_REGION_DATA,
                payload: {
                    data: spotPriceRegionModel,
                    error: false,
                    loading: false,
                },
            });

            dispatch(setSuccessToast());

            if (onSuccess && typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch ({ response }) {
            dispatch({
                type: UserActionTypes.SET_BILLING_REGION_DATA,
                payload: {
                    error: true,
                    loading: false,
                },
            });

            await handleError(response, 'Unable to set billing region:');
        }
    };

export const deleteBillingRegion =
    () =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.BILLING_REGION_WRITE])) {
            return;
        }

        dispatch({
            type: UserActionTypes.SET_BILLING_REGION_DATA,
            payload: {
                error: false,
                loading: true,
            },
        });

        try {
            await new TariffApi(
                createRequestConfiguration(),
            ).tariffDeleteSpotPriceRegion();

            dispatch({
                type: UserActionTypes.SET_BILLING_REGION_DATA,
                payload: {
                    data: null,
                    error: false,
                    loading: false,
                },
            });
        } catch ({ response }) {
            dispatch({
                type: UserActionTypes.SET_BILLING_REGION_DATA,
                payload: {
                    error: true,
                    loading: false,
                },
            });

            await handleError(response, 'Unable to delete billing region:');
        }
    };

export const setGvsAcknowledged = () => (dispatch: Dispatch) => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve();
    }

    dispatch({
        type: UserActionTypes.SET_GVS_ACKNOWLEDGED,
    });

    return new GVSApi(createRequestConfiguration())
        .gvsAcknowledgeRedemption()
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error acknowledging redemption:');
        });
};

export const getGvsInfo =
    () =>
    (dispatch: Dispatch): Promise<GvsUserInfoResponseModel | void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        return new GVSApi(createRequestConfiguration())
            .gvsGetGvsInfo()
            .then((data) => {
                // Remap those as there is an issue with the swagger generated type. The API returns snake_case, not camelCase
                const gvsUserInfo = {
                    // @ts-ignore
                    isGvsCustomer: data?.is_gvs_customer,
                    // @ts-ignore
                    gvsRedeemedAmount: data?.gvs_redeemed_amount,
                };

                dispatch({
                    type: UserActionTypes.SET_GVS_INFO,
                    payload: {
                        gvsUserInfo,
                    },
                });

                return gvsUserInfo;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error getting the GVS info:');
            });
    };

/**
 * ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
 *
 * Note:
 *
 * This action returns info if the customer has a GridX configuration set up.
 * It would return `isGridX: true` only after customer has completed their GridX onboarding
 * and have already found their connected devices through scanning.
 */
export const getUserIsGridX =
    () =>
    (dispatch: Dispatch<any>): Promise<UserAction | void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userIsGridX()
            .then((response: IsGridXUserResponseModel) => {
                const { isGridX } = response;

                return dispatch({
                    type: UserActionTypes.SET_GRIDX_STATUS,
                    payload: { isGridX },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when getting userIsGridX data:');
            });
    };
