/**
 * This file exists only because Webpack has issues
 * importing stuff from the main web app code in the
 * Cypress automation tests. Do not try to waste time
 * extending the Cypress Webpack config, it won't work.
 * Right now it is not possible to import code which
 * references images, stylesheets, etc. Only functions
 * and pure Typescript definitions/types are supported.
 */
import { TFunction } from 'i18next';

import {
    SaleTemplateStepModel,
    SaleTemplateStepModelStatusEnum,
} from '@swagger-http';

import { PowerUnit } from '@tools/enums';
import { GcpTelemetry } from '@store/types';

export const getKiloUnit = (unit: PowerUnit): PowerUnit => {
    let unitSwitch = PowerUnit.kW;

    switch (unit) {
        case PowerUnit.W:
            unitSwitch = PowerUnit.kW;
            break;
        case PowerUnit.Wh:
            unitSwitch = PowerUnit.kWh;
            break;
    }

    return unitSwitch;
};

export const getSelfSufficiency = (
    selfConsumption: number,
    consumption: number,
): number => {
    if (!selfConsumption) {
        return 0;
    }

    const selfsufficiency: number = Math.round(
        (selfConsumption / consumption) * 100,
    );

    if (isNaN(selfsufficiency) || selfsufficiency < 0) {
        return 0;
    }

    if (selfsufficiency > 100) {
        return 100;
    }

    return selfsufficiency;
};

export const getECValues = (gcpLiveData: GcpTelemetry) => {
    const { power, powerConsumption, powerSelfConsumption } = gcpLiveData;

    const consumption: number = powerConsumption || 0;
    const external: number = Math.abs(power || 0);
    const selfsufficiency = getSelfSufficiency(
        powerSelfConsumption || 0,
        consumption,
    );

    return {
        external,
        consumption,
        selfsufficiency,
    };
};

export const localizeNumber = (
    input: number,
    locale: string,
    settings: Intl.NumberFormatOptions = {},
): string =>
    input.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        ...settings,
    });

export const tFunctionMock: TFunction = <T>(t: T) => t;

export const allowPvInstallForGermanUsers = (
    steps: SaleTemplateStepModel[],
): boolean => {
    if (!steps.length) {
        return false;
    }

    const installationStepIndex = steps.findIndex(
        (step: SaleTemplateStepModel) => step.isInstallation,
    );

    return installationStepIndex === -1
        ? false
        : !steps
              .slice(0, installationStepIndex)
              .filter(
                  (step: SaleTemplateStepModel) =>
                      step.status !==
                          SaleTemplateStepModelStatusEnum.Completed &&
                      step.status !== SaleTemplateStepModelStatusEnum.Skipped,
              ).length;
};
