import { SaleModel } from '@swagger-http';

import { SettingsActionTypes } from '@store/enums';
import { SettingsState, SettingsAction } from '@store/types';

export const initialState: SettingsState = {
    energyDevices: {
        hasReadingStartDate: false,
    },

    smartDevices: {
        providers: [],
    },

    sales: {
        loading: false,
        active: [],
        archive: [],
    },

    tenants: {
        details: [],
        config: {},
    },

    campaign: {
        error: false,
        loading: false,
        data: undefined,
    },

    qualtrics: {
        promptsCount: 0,
        dateOfLastAction: undefined,
        isRequestAccepted: undefined,
    },

    ui: {
        theme: undefined,
        appLoading: true,
        tourDisabled: false,
        updateAvailable: false,
        activationPopupSeen: false,
        referralProgramSeen: false,
        dismissedTourRoutes: [],
        mobileAppsBannerSeen: false,
        invalidSmartMeterUkData: false,
        hungarianMarketing08052023: false,
        shouldForceTadoUnlink: false,
        toast: {
            type: null,
            text: null,
            visible: false,
        },
    },

    widgets: {
        mobile: {},
    },
};

const updateSale = (sales: SaleModel[], newSale: SaleModel): SaleModel[] => {
    const saleIndex = sales.findIndex(
        (sale: SaleModel) => sale.id === newSale.id,
    );

    const updatedSales = [...sales];
    updatedSales[saleIndex] = newSale;

    return updatedSales;
};

export default function reducer(
    state: SettingsState = initialState,
    action: SettingsAction,
): SettingsState {
    switch (action.type) {
        case SettingsActionTypes.SET_PROVIDERS:
            return {
                ...state,
                smartDevices: {
                    providers: action.payload,
                },
            };
        case SettingsActionTypes.SET_READING_START_DATE:
            return {
                ...state,
                energyDevices: {
                    hasReadingStartDate: action.payload,
                },
            };

        case SettingsActionTypes.SET_QUALTRICS_DATA:
            return {
                ...state,
                qualtrics: action.payload,
            };

        case SettingsActionTypes.SET_TENANTS_DETAILS:
            return {
                ...state,
                tenants: {
                    ...state.tenants,
                    details: action.payload,
                },
            };

        case SettingsActionTypes.SET_TENANT_CONFIG:
            return {
                ...state,
                tenants: {
                    ...state.tenants,
                    config: action.payload,
                },
            };

        case SettingsActionTypes.GET_SALES:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    active: action.payload,
                },
            };

        case SettingsActionTypes.GET_SALES_ARCHIVE:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    archive: action.payload,
                },
            };

        case SettingsActionTypes.UPDATE_SALE:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    active: updateSale([...state.sales.active], action.payload),
                },
            };

        case SettingsActionTypes.UPDATE_SALE_ARCHIVE:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    archive: updateSale(
                        [...state.sales.archive],
                        action.payload,
                    ),
                },
            };

        case SettingsActionTypes.SET_SALES_LOADING:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: action.payload,
                },
            };

        case SettingsActionTypes.SET_THEME:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    theme: action.payload,
                },
            };

        case SettingsActionTypes.SET_APP_LOADING:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    appLoading: action.payload,
                },
            };

        case SettingsActionTypes.OPEN_UPDATE_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    updateAvailable: true,
                },
            };

        case SettingsActionTypes.CLOSE_UPDATE_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    updateAvailable: false,
                },
            };

        case SettingsActionTypes.SEE_ACTIVATION_POPUP:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    activationPopupSeen: action.payload,
                },
            };

        case SettingsActionTypes.SEE_MOBILE_APPS_BANNER:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    mobileAppsBannerSeen: action.payload,
                },
            };

        case SettingsActionTypes.SET_TADO_UNLINK_STATUS:
            const forceTadoUnlinkStatus =
                action.payload === undefined
                    ? initialState.ui.shouldForceTadoUnlink
                    : !action.payload;

            return {
                ...state,
                ui: {
                    ...state.ui,
                    shouldForceTadoUnlink: forceTadoUnlinkStatus,
                },
            };

        case SettingsActionTypes.SET_MOBILE_WIDGETS_DATA:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    mobile: action.payload,
                },
            };

        case SettingsActionTypes.SET_SMART_METER_ALERT:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    invalidSmartMeterUkData: action.payload,
                },
            };

        case SettingsActionTypes.SEE_REFERRAL_PROGRAM:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    referralProgramSeen: action.payload,
                },
            };

        case SettingsActionTypes.DISMISS_TOUR_ROUTE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    dismissedTourRoutes: action.payload,
                },
            };

        case SettingsActionTypes.DISABLE_TOUR:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    tourDisabled: action.payload,
                },
            };

        case SettingsActionTypes.SET_CAMPAIGN_DETAILS:
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    ...action.payload,
                },
            };

        case SettingsActionTypes.SEE_HUNGARIAN_MARKETING_WIDGET:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    hungarianMarketing08052023: action.payload,
                },
            };

        case SettingsActionTypes.SET_TOAST:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    toast: {
                        ...state.ui.toast,
                        ...action.payload,
                    },
                },
            };

        default:
            return state;
    }
}
